import { useGlobalState } from 'App.state';
import { Config } from 'config';
import downloadjs from 'downloadjs';
import { getAuthHeaders } from 'lib';
import * as React from 'react';

export interface SendRequestOptions {
  download?: boolean;
  openInline?: boolean;
  postData?: any;
  file?: File;
}

export function useFetch() {
  const [response, setResponse] = React.useState<Response>();
  const [objectUrl, setObjectUrl] = React.useState<string>();
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState<Error>();
  const [clockingsAuthUser] = useGlobalState('clockingsAuthUser');

  const sendRequest = React.useCallback(
    async (
      url: string,
      {
        download = false,
        openInline = true,
        postData = undefined,
        file = undefined,
      }: SendRequestOptions,
    ) => {
      setLoading(true);
      setError(undefined);
      setResponse(undefined);
      setObjectUrl(undefined);

      try {
        const res = await fetch(`${Config.API_BASE_URL}/${url}`, {
          headers: {
            ...getAuthHeaders(clockingsAuthUser?.token),
            'Content-Type': file
              ? 'multipart/form-data; boundary=none'
              : 'application/json',
          },
          method: postData !== undefined || file !== undefined ? 'POST' : 'GET',
          body:
            file !== undefined
              ? file
              : postData !== undefined
              ? JSON.stringify(postData)
              : undefined,
        });

        setResponse(res);

        if (!res.ok) {
          const jsonError = await parseJsonError(res);
          throw new Error(jsonError);
        }

        if (download && res.body !== null) {
          let filename = 'unknown';

          const disposition = res.headers.get('Content-Disposition');
          let inline = false;

          if (disposition) {
            const filenameMatch = disposition.match(/.*filename="(.*?)".*/);
            const inlineMatch = disposition.match(/.*inline.*/);

            if (filenameMatch) {
              filename = filenameMatch[1];
            }

            if (inlineMatch) {
              inline = true;
            }
          }

          const fileBlob = await res.blob();

          if (inline) {
            setObjectUrl(URL.createObjectURL(fileBlob));

            if (openInline) {
              window.open(
                URL.createObjectURL(fileBlob),
                '_blank',
                'noopener,noreferrer',
              );
            }
          } else {
            downloadjs(fileBlob, filename);
          }
        }

        return res;
      } catch (error) {
        setError(error);

        throw error;
      } finally {
        setLoading(false);
      }
    },
    [clockingsAuthUser],
  );

  function clearError() {
    setError(undefined);
  }

  return {
    sendRequest,
    response,
    objectUrl,
    loading,
    error,
    clearError,
  };
}

async function parseJsonError(response: Response) {
  let jsonError;

  try {
    const { error, message } = await response.json();
    jsonError = error ?? message;
  } catch (error) {
    const text = await response.text();

    jsonError = text || response.statusText;
  }

  return jsonError;
}
