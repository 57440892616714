import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  card: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  overview: {
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  statCard: {
    width: '100%',
    margin: '0.35em auto',
    border: '1px solid ' + theme.palette.primary.main,
    borderRadius: '0.5em',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: `${theme.spacing(2, 4)} !important`,
    height: '100px',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,

    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
      border: '1px solid ' + theme.palette.primary.dark,
    },
  },
  [theme.breakpoints.down('lg')]: {
    statCard: {
      width: '100%',
    },
  },
  info: {
    '& h5': {
      fontSize: '1.5em',
    },
  },
  total: {
    color: theme.palette.common.white,
  },
  icon: {
    fontSize: '1em',
    overflow: 'visible !important',
  },
}));
