import * as React from 'react';
import {
  DialogProps,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  TextField,
  FormControl,
  Button,
} from '@material-ui/core';
import { useStyles } from './styles';
import { DateTimeFormat } from 'config';
import { format, parse } from 'date-fns';
import { useFetch } from 'hooks';
import { DelayedLinearProgress, SnackbarContext } from 'components';
import { AdminUser } from 'lib/Model/AdminUser';

interface Props extends DialogProps {
  adminUser?: AdminUser;
}

interface FormData {
  id: string;
  username: string;
  password?: string;
}

export const AdminUserDialog: React.FC<Props> = ({ adminUser, ...rest }) => {
  const classes = useStyles();
  const createdOn = !adminUser
    ? new Date()
    : parse(adminUser?.createdOn, DateTimeFormat.GQL, new Date());
  const modifiedOn = adminUser?.modifiedOn
    ? parse(adminUser.modifiedOn, DateTimeFormat.GQL, new Date())
    : undefined;

  const [form, setForm] = React.useState<FormData>(getInitialFormData());
  const snackbar = React.useContext(SnackbarContext);

  const { loading: saveLoading, sendRequest, error } = useFetch();

  return (
    <Dialog maxWidth="md" fullWidth {...rest}>
      <form
        onSubmit={async (e) => {
          e.preventDefault();
          e.stopPropagation();

          try {
            const response = await sendRequest(
              `api/admin-users${adminUser ? `/${adminUser.id}` : ''}`,
              {
                postData: form,
              },
            );
            await response.json();
          } catch (ex) {
            snackbar.error(ex);
            return;
          }
          if (error) {
            snackbar.error(error.message);
            return;
          }
          snackbar.success('Admin user saved.');
          rest && rest.onClose && rest.onClose(e, 'escapeKeyDown');
        }}
      >
        <DialogTitle>{adminUser?.username ?? 'New AdminUser'}</DialogTitle>
        <DialogContent>
          <DelayedLinearProgress loading={saveLoading} />
          <Grid container>
            <Grid item md={12}>
              <FormControl className={classes.formControl} fullWidth>
                <TextField
                  label="Username"
                  value={form.username}
                  required
                  onChange={(e) => {
                    const newValue = e?.target?.value ?? '';
                    setForm((form) => {
                      return {
                        ...form,
                        username: newValue,
                      };
                    });
                  }}
                />
              </FormControl>
              <FormControl className={classes.formControl} fullWidth>
                <TextField
                  label="Password"
                  type="password"
                  value={form.password}
                  onChange={(e) => {
                    const newValue = e?.target?.value ?? '';
                    setForm((form) => {
                      return {
                        ...form,
                        password: newValue,
                      };
                    });
                  }}
                />
              </FormControl>

              <FormControl className={classes.formControl} fullWidth>
                <TextField
                  disabled
                  label="Created On"
                  value={format(createdOn, DateTimeFormat.LONG)}
                />
              </FormControl>

              {modifiedOn && (
                <FormControl className={classes.formControl} fullWidth>
                  <TextField
                    disabled
                    fullWidth
                    label="Modified On"
                    value={format(modifiedOn, DateTimeFormat.LONG)}
                  />
                </FormControl>
              )}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={(e) =>
              rest && rest.onClose && rest.onClose(e, 'escapeKeyDown')
            }
          >
            Close
          </Button>
          <Button color="primary" type="submit">
            Save Changes
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );

  function getInitialFormData(): FormData {
    return {
      id: adminUser?.id.toString() ?? '',
      username: adminUser?.username ?? '',
      password: adminUser?.password ?? '',
    };
  }
};
