import { Checkbox, FormControlLabel, Typography } from '@material-ui/core';
import { DialogProps } from '@material-ui/core/Dialog';
import { FormDialog } from 'components';
import { usePrevious } from 'hooks';
import * as React from 'react';

interface Props {
  dialogProps: DialogProps;
  onSubmit: (
    attendance: boolean,
    attendancePhoto: boolean,
    bioData: boolean,
    errorLog: boolean,
    idCard: boolean,
    operations: boolean,
  ) => void;
}

export const PullDataFromDeviceDialog: React.FC<Props> = ({
  dialogProps,
  onSubmit,
}) => {
  const [pullAttendance, setPullAttendance] = React.useState(false);
  const [pullAttendancePhoto, setPullAttendancePhoto] = React.useState(false);
  const [pullBioData, setPullBioData] = React.useState(false);
  const [pullErrorLog, setPullErrorLog] = React.useState(false);
  const [pullIdCard, setPullIdCard] = React.useState(false);
  const [pullOperations, setPullOperations] = React.useState(false);

  const prevOpen = usePrevious(dialogProps.open);
  const justOpened = !prevOpen;

  React.useEffect(() => {
    if (justOpened) {
      setPullAttendance(false);
      setPullAttendancePhoto(false);
      setPullBioData(false);
      setPullErrorLog(false);
      setPullIdCard(false);
      setPullOperations(false);
    }
  }, [justOpened]);

  return (
    <React.Fragment>
      <FormDialog
        title="Pull data from device"
        submitText="Pull"
        onSubmit={(e) => {
          onSubmit(
            pullAttendance,
            pullAttendancePhoto,
            pullBioData,
            pullErrorLog,
            pullIdCard,
            pullOperations,
          );

          if (dialogProps.onClose) {
            dialogProps.onClose(e);
          }
        }}
        dialogProps={{
          maxWidth: 'sm',
          fullWidth: true,
          ...dialogProps,
        }}
      >
        <Typography variant="body1">
          Data is automatically synced to devices. Using this function you can
          force pull all data from this device.
        </Typography>
        <FormControlLabel
          control={
            <Checkbox
              name="pullAttendance"
              checked={pullAttendance ?? false}
              onChange={(e) => setPullAttendance(e.target.checked)}
            />
          }
          label="Attendance logs (Clockings)"
        />
        <FormControlLabel
          control={
            <Checkbox
              name="pullAttendancePhoto"
              checked={pullAttendancePhoto ?? false}
              onChange={(e) => setPullAttendancePhoto(e.target.checked)}
            />
          }
          label="Attendance Photos"
        />
        <FormControlLabel
          control={
            <Checkbox
              name="pullBioData"
              checked={pullBioData ?? false}
              onChange={(e) => setPullBioData(e.target.checked)}
            />
          }
          label="BioData (Face, Palm)"
        />
        <FormControlLabel
          control={
            <Checkbox
              name="pullErrorLog"
              checked={pullErrorLog ?? false}
              onChange={(e) => setPullErrorLog(e.target.checked)}
            />
          }
          label="Error Log"
        />
        <FormControlLabel
          control={
            <Checkbox
              name="pullIdCard"
              checked={pullIdCard ?? false}
              onChange={(e) => setPullIdCard(e.target.checked)}
            />
          }
          label="ID Cards"
        />
        <FormControlLabel
          control={
            <Checkbox
              name="pullOperations"
              checked={pullOperations ?? false}
              onChange={(e) => setPullOperations(e.target.checked)}
            />
          }
          label="Employees and operations log"
        />
      </FormDialog>
    </React.Fragment>
  );
};
