import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  Theme,
  useMediaQuery,
  DialogTitleProps,
} from '@material-ui/core';
import { DialogProps } from '@material-ui/core/Dialog';
import { useTheme } from '@material-ui/core';
import * as React from 'react';
import { DelayedLinearProgress } from '../DelayedLinearProgress';
import { ConfirmDialog } from 'components/ConfirmDialog';

const useStyles = makeStyles((theme) => ({
  invalidForm: {
    '&& input': {
      '&:invalid:not(:focus)': {
        borderBottom: `1px solid ${theme.palette.danger.main}`,
        // To overlap the border of parent element
        zIndex: 1,
      },
    },
  },
}));

export interface FormDialogProps {
  dialogProps: DialogProps;
  dialogTitleProps?: DialogTitleProps;

  title?: React.ReactNode;
  cancelText?: string;
  submitText?: string;
  loading?: boolean;
  confirmCancel?: boolean;
  disableSubmitButton?: boolean;
  contentStyle?: React.CSSProperties;
  onSubmit?(e: React.FormEvent<HTMLFormElement>): void;
}

export const FormDialog: React.FC<FormDialogProps> = ({
  children,
  title = 'Dialog',
  dialogProps,
  dialogTitleProps,
  contentStyle,
  cancelText = 'Cancel',
  submitText = 'Save',
  loading = false,
  confirmCancel = false,
  onSubmit,
  disableSubmitButton,
}) => {
  const {
    breakpoints: { up },
  } = useTheme<Theme>();
  const classes = useStyles();
  const isDesktop = useMediaQuery(up('md'));

  // Random form name to isolate multiple forms on the same page
  const formId = React.useRef(Math.random().toString(36).substring(7));

  const [confirmCancelOpen, setConfirmCancelOpen] = React.useState(false);

  return (
    <Dialog
      fullScreen={!isDesktop}
      {...dialogProps}
      disableBackdropClick={confirmCancel}
      onBackdropClick={(e) => confirmCancel && setConfirmCancelOpen(true)}
      disableEscapeKeyDown={confirmCancel}
      onEscapeKeyDown={(e) => confirmCancel && setConfirmCancelOpen(true)}
    >
      <DialogTitle
        {...dialogTitleProps}
        disableTypography={typeof title !== 'string'}
      >
        {title}
      </DialogTitle>

      <DialogContent style={contentStyle}>
        <form
          id={formId.current}
          onSubmit={(e) => {
            // Prevent postback
            e.preventDefault();

            // Prevent bubbling up to parent forms
            e.stopPropagation();

            const { currentTarget: form } = e;

            if (!form.checkValidity()) {
              form.reportValidity();

              // Find a way to use error prop some day (Available on Input & TextField) https://material-ui.com/demos/text-fields/#inputs

              form.classList.add(classes.invalidForm);
              return;
            }

            if (onSubmit) {
              onSubmit(e);
            }
          }}
          noValidate
        >
          {children}
        </form>
      </DialogContent>

      <DialogActions>
        <Button
          onClick={(e) => {
            if (confirmCancel) {
              setConfirmCancelOpen(true);
              return;
            }

            if (dialogProps.onClose) {
              dialogProps.onClose(e);
            }
          }}
          color="default"
          disabled={loading}
        >
          {cancelText}
        </Button>

        {submitText && (
          <Button
            type="submit"
            form={formId.current}
            color="primary"
            disabled={loading || disableSubmitButton}
          >
            {submitText}
          </Button>
        )}
      </DialogActions>

      <DelayedLinearProgress variant="query" loading={loading} />

      <ConfirmDialog
        title="Confirm"
        content="Are you sure you want to close this dialog? Any unsaved changes will be lost."
        onConfirm={dialogProps.onClose}
        dialogProps={{
          open: confirmCancelOpen,
          onClose: () => setConfirmCancelOpen(false),
        }}
      />
    </Dialog>
  );
};
