import * as React from 'react';
import {
  DialogProps,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  TextField,
  FormControl,
  Button,
  Typography,
} from '@material-ui/core';
import { useStyles } from './styles';
import { DateTimeFormat } from 'config';
import { format, parse } from 'date-fns';
import { useFetch } from 'hooks';
import { DelayedLinearProgress, FormField, SnackbarContext } from 'components';
import { FtpConfig } from 'lib/Model/FtpConfig';
import { Alert } from '@material-ui/lab';

interface Props extends DialogProps {
  ftpConfig?: FtpConfig;
}

interface FormData {
  id: string;
  username: string;
  password?: string;
  host?: string;
  pushClockings: boolean;
  pullEmployees: boolean;
  terminateEmployees: boolean;
  frequencyInMinutes: number;
}

export const FtpConfigDialog: React.FC<Props> = ({ ftpConfig, ...rest }) => {
  const classes = useStyles();
  const createdOn = !ftpConfig
    ? new Date()
    : parse(ftpConfig?.createdOn, DateTimeFormat.GQL, new Date());
  const modifiedOn = ftpConfig?.modifiedOn
    ? parse(ftpConfig.modifiedOn, DateTimeFormat.GQL, new Date())
    : undefined;

  const [form, setForm] = React.useState<FormData>(getInitialFormData());
  const snackbar = React.useContext(SnackbarContext);

  const { loading: saveLoading, sendRequest, error } = useFetch();

  return (
    <Dialog maxWidth="md" fullWidth {...rest}>
      <form
        onSubmit={async (e) => {
          e.preventDefault();
          e.stopPropagation();

          try {
            const response = await sendRequest(
              `api/ftp-configs${ftpConfig ? `/${ftpConfig.id}` : ''}`,
              {
                postData: form,
              },
            );
            await response.json();
          } catch (ex) {
            snackbar.error(ex);
            return;
          }
          if (error) {
            snackbar.error(error.message);
            return;
          }
          snackbar.success('FTP config saved and background job is scheduled.');
          rest && rest.onClose && rest.onClose(e, 'escapeKeyDown');
        }}
      >
        <DialogTitle>{ftpConfig?.username ?? 'New FtpConfig'}</DialogTitle>
        <DialogContent>
          <DelayedLinearProgress loading={saveLoading} />
          <Grid container>
            <Grid item md={12}>
              <FormControl className={classes.formControl} fullWidth>
                <TextField
                  label="Host"
                  type="text"
                  value={form.host}
                  onChange={(e) => {
                    const newValue = e?.target?.value ?? '';
                    setForm((form) => {
                      return {
                        ...form,
                        host: newValue,
                      };
                    });
                  }}
                />
              </FormControl>

              <FormControl className={classes.formControl} fullWidth>
                <TextField
                  label="Username"
                  value={form.username}
                  required
                  onChange={(e) => {
                    const newValue = e?.target?.value ?? '';
                    setForm((form) => {
                      return {
                        ...form,
                        username: newValue,
                      };
                    });
                  }}
                />
              </FormControl>
              <FormControl className={classes.formControl} fullWidth>
                <TextField
                  label="Password"
                  type="password"
                  value={form.password}
                  onChange={(e) => {
                    const newValue = e?.target?.value ?? '';
                    setForm((form) => {
                      return {
                        ...form,
                        password: newValue,
                      };
                    });
                  }}
                />
              </FormControl>

              <FormControl className={classes.formControl} fullWidth>
                <TextField
                  label="Frequency in minutes"
                  type="number"
                  value={form.frequencyInMinutes}
                  onChange={(e) => {
                    const newValue = e?.target?.value ?? '0';
                    setForm((form) => {
                      return {
                        ...form,
                        frequencyInMinutes: parseInt(newValue, 10),
                      };
                    });
                  }}
                />
              </FormControl>

              {!form.frequencyInMinutes && (
                <Alert severity="warning">
                  <Typography variant="body1">
                    Zero frequency means this FTP config does not automatically
                    syncing.
                  </Typography>
                </Alert>
              )}

              <FormControl className={classes.formControl} fullWidth>
                <FormField
                  name="pushClockings"
                  value={form.pushClockings}
                  label="Automatically upload any clockings to this FTP"
                  onChange={(e) => {
                    setForm((form) => {
                      return {
                        ...form,
                        pushClockings: !form.pushClockings,
                      };
                    });
                  }}
                  type="checkbox"
                />
                <FormField
                  name="pullEmployees"
                  value={form.pullEmployees}
                  label="Automatically download employees from this FTP"
                  onChange={(e) => {
                    setForm((form) => {
                      return {
                        ...form,
                        pullEmployees: !form.pullEmployees,
                      };
                    });
                  }}
                  type="checkbox"
                />
                <FormField
                  name="terminateEmployees"
                  value={form.terminateEmployees}
                  label="Automatically delete terminated employees from this FTP"
                  onChange={(e) => {
                    setForm((form) => {
                      return {
                        ...form,
                        terminateEmployees: !form.terminateEmployees,
                      };
                    });
                  }}
                  type="checkbox"
                />
              </FormControl>

              <FormControl className={classes.formControl} fullWidth>
                <TextField
                  disabled
                  label="Created On"
                  value={format(createdOn, DateTimeFormat.LONG)}
                />
              </FormControl>

              {modifiedOn && (
                <FormControl className={classes.formControl} fullWidth>
                  <TextField
                    disabled
                    fullWidth
                    label="Modified On"
                    value={format(modifiedOn, DateTimeFormat.LONG)}
                  />
                </FormControl>
              )}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={(e) =>
              rest && rest.onClose && rest.onClose(e, 'escapeKeyDown')
            }
          >
            Close
          </Button>
          <Button color="primary" type="submit">
            Save Changes
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );

  function getInitialFormData(): FormData {
    return {
      id: ftpConfig?.id.toString() ?? '',
      username: ftpConfig?.username ?? '',
      password: ftpConfig?.password ?? '',
      host: !ftpConfig ? '52.58.232.77' : ftpConfig?.host ?? '',
      frequencyInMinutes: ftpConfig?.frequencyInMinutes ?? 0,
      pushClockings: ftpConfig?.pushClockings ?? false,
      pullEmployees: ftpConfig?.pullEmployees ?? false,
      terminateEmployees: ftpConfig?.terminateEmployees ?? false,
    };
  }
};
