import { AuthUser } from './AuthUser';

export const deserializeAuth = (
  auth: string | null | undefined,
): AuthUser | null => {
  if (auth) {
    try {
      return JSON.parse(auth) as AuthUser;
    } catch (error) {
      return null;
    }
  }
  return null;
};
