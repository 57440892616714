import * as React from 'react';
import {
  DialogProps,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  FormControl,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import { Employee } from 'lib/Model/Employee';
import { useFetch } from 'hooks';
import { DelayedLinearProgress, SnackbarContext } from 'components';
import { Device } from 'lib/Model/Device';
import { Alert } from '@material-ui/lab';

interface Props extends DialogProps {
  employee: Employee;
  setEmployeeAfterSave(emp: Employee): void;
}

interface FormData {
  id: string;
  devices: number[];
}

export const EmployeeDevicesDialog: React.FC<Props> = ({
  employee,
  setEmployeeAfterSave,
  ...rest
}) => {
  const [form, setForm] = React.useState<FormData>(getInitialFormData());
  const snackbar = React.useContext(SnackbarContext);
  const [devices, setDevices] = React.useState<Device[] | undefined>();

  const {
    loading: loadingDevices,
    sendRequest: sendDevicesRequest,
  } = useFetch();

  React.useEffect(() => {
    async function fetchData() {
      try {
        const response = await sendDevicesRequest(`api/devices`, {});
        const jsonResponse = await response.json();
        if (jsonResponse) {
          setDevices(jsonResponse);
        }
      } catch (ex) {}
    }
    fetchData();
  }, [sendDevicesRequest, setDevices]);

  const { loading: saveLoading, sendRequest, error } = useFetch();

  return (
    <Dialog maxWidth="md" fullWidth {...rest}>
      <form
        onSubmit={async (e) => {
          if (loadingDevices) {
            return false;
          }
          e.preventDefault();
          e.stopPropagation();

          try {
            const response = await sendRequest(
              `api/users/${employee.id}/saveWhitelist`,
              {
                postData: {
                  deviceIds: form.devices.join(',') ?? '',
                },
              },
            );
            await response.json();
          } catch (ex) {
            snackbar.error(ex);
            return;
          }
          if (error) {
            snackbar.error(error.message);
            return;
          }

          setEmployeeAfterSave({
            ...employee,
            userDeviceWhitelist: form.devices,
          });

          snackbar.success('Whitelist saved and will be synced to devices.');
          rest && rest.onClose && rest.onClose(e, 'escapeKeyDown');
        }}
      >
        <DialogTitle>{employee.name} - Device Whitelist</DialogTitle>
        <DialogContent>
          <DelayedLinearProgress loading={saveLoading} />
          <Typography variant="body1">
            Select some devices to restrict this employee to just the selected
            devices.
          </Typography>
          <div style={{ padding: 16 }}>
            {devices?.map((d) => (
              <FormControl fullWidth key={`device-${d.id}`}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="device[]"
                      value={d.id}
                      checked={form.devices.indexOf(d.id) >= 0}
                      onChange={(e) =>
                        setForm((f) => {
                          const newDevices = f.devices.slice();
                          if (newDevices.indexOf(d.id) >= 0) {
                            newDevices.splice(newDevices.indexOf(d.id), 1);
                          } else {
                            newDevices.push(d.id);
                          }
                          return {
                            ...f,
                            devices: newDevices,
                          };
                        })
                      }
                    />
                  }
                  label={`${d.name} (${d.serialNumber})`}
                />
              </FormControl>
            ))}
          </div>
          {!form.devices.length && (
            <Alert severity="warning">
              <Typography variant="body1">
                If you do not select any device, the employee will be able to
                use all punch clock.
              </Typography>
            </Alert>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={(e) =>
              rest && rest.onClose && rest.onClose(e, 'escapeKeyDown')
            }
          >
            Close
          </Button>
          <Button color="primary" type="submit" disabled={loadingDevices}>
            Save Changes
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );

  function getInitialFormData(): FormData {
    return {
      id: employee.id.toString(),
      devices: employee.userDeviceWhitelist ?? [],
    };
  }
};
