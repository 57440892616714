import * as React from 'react';
import {
  DialogProps,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  TextField,
  FormControl,
  Button,
  Chip,
  Select,
  InputLabel,
  Typography,
  Divider,
} from '@material-ui/core';
import { Employee } from 'lib/Model/Employee';
import { useStyles } from './styles';
import {
  Face,
  Fingerprint,
  CreditCard,
  VpnKey,
  AccountCircle,
  PanTool,
} from '@material-ui/icons';
import { Config, DateTimeFormat } from 'config';
import { format, parse } from 'date-fns';
import { useFetch } from 'hooks';
import { DelayedLinearProgress, SnackbarContext } from 'components';
import { Alert } from '@material-ui/lab';

interface Props extends DialogProps {
  employee?: Employee;
}

interface FormData {
  id: string;
  employeeNumber: string;
  name: string;
  privilege: string;
  cardNumber: string;
  password: string;
  departmentCode: string;
  departmentName: string;
}

export const EmployeeDialog: React.FC<Props> = ({ employee, ...rest }) => {
  const classes = useStyles();
  const fingerPrints = employee?.fingerPrints?.length ?? 0;
  const createdOn = !employee
    ? new Date()
    : parse(employee?.createdOn, DateTimeFormat.GQL, new Date());
  const modifiedOn = employee?.modifiedOn
    ? parse(employee.modifiedOn, DateTimeFormat.GQL, new Date())
    : undefined;

  // eslint-disable-next-line
  const specialCharFormat = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;

  const [form, setForm] = React.useState<FormData>(getInitialFormData());
  const snackbar = React.useContext(SnackbarContext);

  const { loading: saveLoading, sendRequest, error } = useFetch();

  return (
    <Dialog maxWidth={employee?.isVisitor ? 'sm' : 'md'} fullWidth {...rest}>
      <form
        onSubmit={async (e) => {
          e.preventDefault();
          e.stopPropagation();

          try {
            const response = await sendRequest(
              `api/users${employee ? `/${employee.id}` : ''}`,
              {
                postData: form,
              },
            );
            await response.json();
          } catch (ex) {
            snackbar.error(ex);
            return;
          }
          if (error) {
            snackbar.error(error.message);
            return;
          }
          snackbar.success('User saved and will be synced to devices.');
          rest && rest.onClose && rest.onClose(e, 'escapeKeyDown');
        }}
      >
        <DialogTitle>{employee?.name ?? 'New Employee'}</DialogTitle>
        <DialogContent>
          <DelayedLinearProgress loading={saveLoading} />
          {!employee?.isVisitor ? (
            <Grid container>
              <Grid item md={5} style={{ textAlign: 'center' }}>
                <div>
                  {employee?.userPicFileName ? (
                    <img
                      className={classes.userPic}
                      src={`${Config.API_BASE_URL}${employee.userPicFileName}`}
                      alt={employee.name}
                      style={{ maxWidth: '100%', maxHeight: 300 }}
                    />
                  ) : (
                    <div className={classes.noPic}>
                      <AccountCircle fontSize="large" />
                    </div>
                  )}
                </div>
                <div>
                  <Chip
                    className={classes.chip}
                    color={
                      Boolean(employee?.cardNumber) ? 'secondary' : 'default'
                    }
                    icon={<CreditCard />}
                    label="Card"
                  />
                  <Chip
                    className={classes.chip}
                    color={
                      Boolean(employee?.password) ? 'secondary' : 'default'
                    }
                    icon={<VpnKey />}
                    label="Passcode"
                  />
                </div>
                <div>
                  <Chip
                    className={classes.chip}
                    color={fingerPrints > 0 ? 'secondary' : 'default'}
                    icon={<Fingerprint />}
                    label={`${fingerPrints} fingerprint${
                      fingerPrints !== 1 ? 's' : ''
                    }`}
                  />
                  <Chip
                    className={classes.chip}
                    color={
                      Boolean(employee?.faceData) ? 'secondary' : 'default'
                    }
                    icon={<Face />}
                    label="Face data"
                  />
                  <Chip
                    className={classes.chip}
                    color={
                      Boolean(employee?.palmData) ? 'secondary' : 'default'
                    }
                    icon={<PanTool />}
                    label="Palm"
                  />
                </div>
                <div></div>
              </Grid>
              <Grid item md={7}>
                <FormControl className={classes.formControl} fullWidth>
                  <TextField
                    label="Unique ID (Code)"
                    type="text"
                    value={employee?.employeeNumber ?? form.employeeNumber}
                    required
                    disabled={Boolean(employee)}
                    onChange={(e) => {
                      if (employee) {
                        return;
                      }
                      const newValue = e?.target?.value ?? '';
                      setForm((form) => {
                        return {
                          ...form,
                          employeeNumber: newValue,
                        };
                      });
                    }}
                  />
                  {(employee?.employeeNumber &&
                    specialCharFormat.test(employee?.employeeNumber)) ||
                    (specialCharFormat.test(form.employeeNumber) && (
                      <Alert severity="warning">
                        <Typography variant="body1">
                          Please note that employees with non-numeric Unique IDs
                          will not show up on the punch clock devices unless
                          your device supports 'alphanumeric' employee codes.
                        </Typography>
                      </Alert>
                    ))}
                </FormControl>
                <FormControl className={classes.formControl} fullWidth>
                  <TextField
                    label="Name"
                    value={form.name}
                    required
                    onChange={(e) => {
                      const newValue = e?.target?.value ?? '';
                      setForm((form) => {
                        return {
                          ...form,
                          name: newValue,
                        };
                      });
                    }}
                  />
                </FormControl>
                <FormControl className={classes.formControl} fullWidth>
                  <TextField
                    label="Passcode (Number)"
                    type="number"
                    value={form.password}
                    onChange={(e) => {
                      const newValue = e?.target?.value ?? '';
                      setForm((form) => {
                        return {
                          ...form,
                          password: newValue,
                        };
                      });
                    }}
                  />
                </FormControl>
                <FormControl className={classes.formControl} fullWidth>
                  <TextField
                    label="Card Number"
                    type="text"
                    value={form.cardNumber}
                    onChange={(e) => {
                      const newValue = e?.target?.value ?? '';
                      setForm((form) => {
                        return {
                          ...form,
                          cardNumber: newValue,
                        };
                      });
                    }}
                  />
                </FormControl>
                <FormControl className={classes.formControl} fullWidth>
                  <InputLabel htmlFor="role-select">Role</InputLabel>
                  <Select
                    native
                    value={form.privilege}
                    onChange={(e) => {
                      const newValue = (e?.target?.value as string) ?? '';
                      setForm((form) => {
                        return {
                          ...form,
                          privilege: newValue,
                        };
                      });
                    }}
                    inputProps={{
                      name: 'role',
                      id: 'role-select',
                    }}
                  >
                    <option value={0}>Normal User</option>
                    <option value={2}>Registrar</option>
                    <option value={14}>Super Admin</option>
                    {employee && !employee.role && (
                      <option value={employee.privilege}>
                        {employee.privilege}
                      </option>
                    )}
                  </Select>
                </FormControl>

                <FormControl className={classes.formControl} fullWidth>
                  <TextField
                    label="Department Code"
                    type="text"
                    value={form.departmentCode}
                    onChange={(e) => {
                      const newValue = e?.target?.value ?? '';
                      setForm((form) => {
                        return {
                          ...form,
                          departmentCode: newValue,
                        };
                      });
                    }}
                  />
                </FormControl>

                <FormControl className={classes.formControl} fullWidth>
                  <TextField
                    label="Department"
                    type="text"
                    value={form.departmentName}
                    onChange={(e) => {
                      const newValue = e?.target?.value ?? '';
                      setForm((form) => {
                        return {
                          ...form,
                          departmentName: newValue,
                        };
                      });
                    }}
                  />
                </FormControl>

                <FormControl className={classes.formControl} fullWidth>
                  <TextField
                    disabled
                    label="Created On"
                    value={format(createdOn, DateTimeFormat.LONG)}
                  />
                </FormControl>

                {modifiedOn && (
                  <FormControl className={classes.formControl} fullWidth>
                    <TextField
                      disabled
                      fullWidth
                      label="Modified On"
                      value={format(modifiedOn, DateTimeFormat.LONG)}
                    />
                  </FormControl>
                )}
              </Grid>
            </Grid>
          ) : (
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="h6">Reference</Typography>
                <Typography variant="body1">
                  {employee.employeeNumber}
                </Typography>
                <Divider className={classes.divider} />
                <Typography variant="h6">Full name</Typography>
                <Typography variant="body1">{employee.name}</Typography>
                <Divider className={classes.divider} />
                <Typography variant="h6">Company</Typography>
                <Typography variant="body1">
                  {employee.visitorCompany}
                </Typography>
                <Divider className={classes.divider} />
                <Typography variant="h6">Contact number</Typography>
                <Typography variant="body1">
                  {employee.visitorContactNumber}
                </Typography>
                <Divider className={classes.divider} />
                <Typography variant="h6">Purpose of visit</Typography>
                <Typography variant="body1">
                  {employee.visitorPurpose}
                </Typography>
                <Divider className={classes.divider} />
                <Typography variant="h6">Date of registration</Typography>
                <Typography variant="body1">
                  {format(createdOn, DateTimeFormat.LONG)}
                </Typography>
              </Grid>
            </Grid>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={(e) =>
              rest && rest.onClose && rest.onClose(e, 'escapeKeyDown')
            }
          >
            Close
          </Button>
          {!employee?.isVisitor && (
            <Button color="primary" type="submit">
              Save Changes
            </Button>
          )}
        </DialogActions>
      </form>
    </Dialog>
  );

  function getInitialFormData(): FormData {
    return {
      id: employee?.id.toString() ?? '',
      employeeNumber: employee?.employeeNumber?.toString() ?? '',
      name: employee?.name ?? '',
      password: employee?.password ?? '',
      cardNumber: employee?.cardNumber ?? '',
      privilege: employee?.privilege ?? '0',
      departmentCode: employee?.departmentCode ?? '',
      departmentName: employee?.departmentName ?? '',
    };
  }
};
