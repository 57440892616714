import { makeStyles, Typography } from '@material-ui/core';
import * as React from 'react';

interface Props
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  linkHref: string;
  logoSrc: string;
  text: React.ReactNode;
}

const useStyles = makeStyles((theme) => ({
  logo: {
    position: 'relative',
    padding: '15px 25px',
    zIndex: 4,
    margin: '20px 0px 0px 0px',
  },
  logoLink: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 18,
    textDecoration: 'none',
    color: 'inherit',
  },
  logoImage: {
    width: 35,
    marginRight: theme.spacing(1),
  },
  logoText: {
    fontSize: 18,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

export const SidebarLogo: React.FC<Props> = ({
  linkHref,
  logoSrc,
  text,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <div className={classes.logo} {...rest}>
      <a
        href={linkHref}
        target="_blank"
        rel="noopener noreferrer"
        className={classes.logoLink}
        title={typeof text === 'string' ? text : undefined}
      >
        <img src={logoSrc} alt="logo" className={classes.logoImage} />

        <Typography
          color="inherit"
          component="span"
          className={classes.logoText}
        >
          {text}
        </Typography>
      </a>
    </div>
  );
};
