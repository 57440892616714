import * as React from 'react';
import {
  DialogProps,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  FormControl,
  Button,
} from '@material-ui/core';
import { useStyles } from './styles';
import { useFetch } from 'hooks';
import { DelayedLinearProgress, SnackbarContext } from 'components';

interface Props extends DialogProps {
  onClaimed?(): void;
}

export const ClaimDeviceDialog: React.FC<Props> = ({ onClaimed, ...rest }) => {
  const classes = useStyles();
  const { loading: fetchLoading, sendRequest, error } = useFetch();
  const [deviceSerialNumber, setDeviceSerialNumber] = React.useState('');
  const snackbar = React.useContext(SnackbarContext);

  return (
    <Dialog maxWidth="md" fullWidth {...rest}>
      <DelayedLinearProgress loading={fetchLoading} />
      <DialogTitle>Claim device</DialogTitle>
      <DialogContent>
        <FormControl className={classes.formControl} fullWidth>
          <TextField
            label="Serial number"
            type="text"
            value={deviceSerialNumber}
            onChange={(e) => setDeviceSerialNumber(e.target.value)}
            autoFocus
            required
          />
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={(e) =>
            rest && rest.onClose && rest.onClose(e, 'escapeKeyDown')
          }
        >
          Cancel
        </Button>
        <Button
          onClick={async (e) => {
            try {
              await sendRequest(`api/devices/create-web-device`, {
                postData: {},
              });
              if (error) {
                throw new Error(error.message);
              }
              snackbar.success('Device created.');
              onClaimed && onClaimed();
              rest && rest.onClose && rest.onClose(e, 'escapeKeyDown');
            } catch (error) {
              snackbar.error(error.message);
              return;
            }
          }}
          disabled={fetchLoading}
        >
          Create a web device
        </Button>
        <Button
          onClick={async (e) => {
            try {
              await sendRequest(`api/devices/${deviceSerialNumber}/claim`, {
                postData: {},
              });
              if (error) {
                throw new Error(error.message);
              }
              snackbar.success('Device claimed.');
              onClaimed && onClaimed();
              rest && rest.onClose && rest.onClose(e, 'escapeKeyDown');
            } catch (error) {
              snackbar.error(error.message);
              return;
            }
          }}
          color="primary"
          disabled={fetchLoading}
        >
          Save Changes
        </Button>
      </DialogActions>
    </Dialog>
  );
};
