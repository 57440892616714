import * as React from 'react';
import {
  Grid,
  Card,
  Button,
  CardHeader,
  IconButton,
  Chip,
  CardContent,
  Typography,
  Tooltip,
} from '@material-ui/core';
import { ConfirmDialog, DelayedLinearProgress, EmptyView } from 'components';
import { useStyles } from './styles';
import {
  Face,
  Fingerprint,
  PanTool,
  CheckCircle,
  Cancel,
  People,
  Schedule,
  Refresh,
  Add,
  SignalCellularOff,
  SignalCellular4Bar,
  OpenInNew,
} from '@material-ui/icons';
import {
  ClaimDeviceDialog,
  DeviceDialog,
  PullDataFromDeviceDialog,
  PushDataToDeviceDialog,
} from './components';
import { Device } from 'lib/Model/Device';
import { useFetch } from 'hooks';
import { SortableTable } from 'components/SortableTable';
import { SortableTableHeader } from 'components/SortableTable/components';
import { Alert } from '@material-ui/lab';
import { PopperDropdownMenu } from 'components/PopperDropdownMenu';
import { format, parse, subMinutes } from 'date-fns';
import { DateTimeFormat } from 'config';
import { isBefore } from 'date-fns';

export const Devices: React.FC = () => {
  const classes = useStyles();
  const [devices, setDevices] = React.useState<Device[] | undefined>();
  const [showClaimDevice, setShowClaimDevice] = React.useState(false);
  const [wipeFromDevice, setWipeFromDevice] = React.useState<
    Device | undefined
  >();
  const [selectedDevice, setSelectedDevice] = React.useState<
    Device | undefined
  >();

  const [openPushDialog, setOpenPushDialog] = React.useState(false);
  const [selectedPushDevice, setSelectedPushDevice] = React.useState<
    Device | undefined
  >();

  const [openPullDialog, setOpenPullDialog] = React.useState(false);
  const [selectedPullDevice, setSelectedPullDevice] = React.useState<
    Device | undefined
  >();

  const { loading: fetchLoading, sendRequest, error } = useFetch();

  React.useEffect(() => {
    async function fetchData() {
      try {
        const response = await sendRequest('api/devices', {});
        const jsonResponse = await response.json();
        if (jsonResponse) {
          setDevices(jsonResponse);
        }
      } catch (ex) {}
    }
    fetchData();
  }, [setDevices, sendRequest]);

  const signalCutoff = subMinutes(new Date(), 5);

  const columns: SortableTableHeader[] = [
    { key: 'serialNumber', label: 'SN', sortable: true },
    { key: 'name', label: 'Name', sortable: true },
    { key: 'authorised', label: 'Authorized' },
    { key: 'ipAddress', label: 'IP Address' },
    { key: 'records', label: 'Records' },
    { key: 'biometrics', label: 'Biometrics' },
    { key: 'ping', label: 'Last signal' },
    { key: 'commands', label: 'Commands' },
    { key: 'actions', label: '' },
  ];

  const rows =
    devices?.map((device) => {
      const pingDate = parse(device.ping, DateTimeFormat.GQL, new Date());
      const isAlarm = isBefore(pingDate, signalCutoff);
      return {
        key: device.id.toString(),
        cells: [
          {
            key: 'serialNumber',
            display: (
              <Button
                variant="text"
                color="primary"
                className={classes.primaryLink}
                onClick={() => setSelectedDevice(device)}
              >
                {device.serialNumber}
              </Button>
            ),
            sortValue: device.serialNumber,
          },
          {
            key: 'name',
            display: (
              <Typography variant="body1">
                {device.name}
                {device.isWebDevice && (
                  <OpenInNew
                    onClick={() => {
                      window.open(`/${device.serialNumber}/punch-clock`);
                    }}
                    fontSize="small"
                    style={{
                      marginLeft: 8,
                      position: 'relative',
                      top: 3,
                      cursor: 'pointer',
                    }}
                  />
                )}
              </Typography>
            ),
            sortValue: device.name,
          },
          {
            key: 'authorized',
            display: device.isAuthorized ? (
              <CheckCircle style={{ color: '#50b05e' }} />
            ) : (
              <Cancel color="error" />
            ),
            sortValue: device.isAuthorized ? 1 : 0,
          },
          {
            key: 'ipAddress',
            display: device.ipAddress,
            sortValue: device.ipAddress,
          },
          {
            key: 'records',
            display: (
              <React.Fragment>
                <Chip
                  size="small"
                  className={classes.chip}
                  color={'default'}
                  icon={<People />}
                  label={device.userCount.toString()}
                />
                <Chip
                  size="small"
                  className={classes.chip}
                  color={'default'}
                  icon={<Schedule />}
                  label={device.transactionCount.toString()}
                />
              </React.Fragment>
            ),
            sortValue: 1,
          },
          {
            key: 'biometrics',
            display: (
              <React.Fragment>
                <Chip
                  size="small"
                  className={classes.chip}
                  color={'default'}
                  icon={<Fingerprint />}
                  label={device.fingerprintCount.toString()}
                />
                <Chip
                  size="small"
                  className={classes.chip}
                  color={'default'}
                  icon={<Face />}
                  label={device.faceCount.toString()}
                />
                {device.multiBioDataSupport &&
                  device.multiBioDataSupport !== '0' && (
                    <Chip
                      size="small"
                      className={classes.chip}
                      color={'default'}
                      icon={<PanTool />}
                      label={device.palmVeinCount.toString()}
                    />
                  )}
              </React.Fragment>
            ),
          },
          {
            key: 'ping',
            display: !device.isWebDevice && (
              <span>
                <Tooltip title={format(pingDate, DateTimeFormat.LONG)}>
                  {isAlarm ? (
                    <SignalCellularOff color="error" />
                  ) : (
                    <SignalCellular4Bar style={{ color: '#50b05e' }} />
                  )}
                </Tooltip>
              </span>
            ),
            sortValue: device.ping,
          },
          {
            key: 'commands',
            display: !device.isWebDevice
              ? `${device.commands.length} pending`
              : '',
            sortValue: device.commands.length,
          },
          {
            key: 'actions',
            display: (
              <PopperDropdownMenu
                menuItems={[
                  device.isAuthorized
                    ? {
                        label: 'Deauthorize',
                        onClick: async () => {
                          const response = await sendRequest(
                            `api/devices/${device.serialNumber}/deauthorize`,
                            { postData: {} },
                          );
                          updateDeviceLocalData(await response.json());
                        },
                      }
                    : {
                        label: 'Authorize',
                        onClick: async () => {
                          const response = await sendRequest(
                            `api/devices/${device.serialNumber}/authorize`,
                            { postData: {} },
                          );
                          updateDeviceLocalData(await response.json());
                        },
                      },
                  device.isAuthorized
                    ? null
                    : {
                        label: 'Unlink Device',
                        onClick: async () => {
                          try {
                            const response = await sendRequest(
                              `api/devices/${device.serialNumber}/unlink`,
                              { postData: {} },
                            );
                            const jsonResponse = await response.json();
                            updateDeviceLocalData(jsonResponse);

                            // Refetch the device list with to keep the UI updated
                            if (response.status === 200) {
                              const updateDeviceList = await sendRequest(
                                'api/devices',
                                {},
                              );

                              const responseJson = await updateDeviceList.json();
                              setDevices(responseJson);
                            }
                          } catch (ex) {}
                        },
                      },
                  {
                    label: 'Clear pending commands',
                    onClick: async () => {
                      const response = await sendRequest(
                        `api/devices/${device.serialNumber}/clearCommands`,
                        { postData: {} },
                      );
                      updateDeviceLocalData(await response.json());
                    },
                  },
                  {
                    label: 'Generate commands now',
                    onClick: async () => {
                      const response = await sendRequest(
                        `api/devices/${device.serialNumber}/prepareCommands`,
                        { postData: {} },
                      );
                      updateDeviceLocalData(await response.json());
                    },
                  },
                  {
                    label: 'Get status info from device',
                    onClick: async () => {
                      const response = await sendRequest(
                        `api/devices/${device.serialNumber}/info`,
                        { postData: {} },
                      );
                      updateDeviceLocalData(await response.json());
                    },
                  },
                  {
                    label: 'Pull from device',
                    onClick: () => {
                      setSelectedPullDevice(device);
                      setOpenPullDialog(true);
                    },
                  },
                  {
                    label: 'Push to device',
                    onClick: () => {
                      setSelectedPushDevice(device);
                      setOpenPushDialog(true);
                    },
                  },
                  {
                    label: 'Check no-resync',
                    onClick: async () => {
                      const response = await sendRequest(
                        `api/devices/${device.serialNumber}/check`,
                        { postData: {} },
                      );
                      updateDeviceLocalData(await response.json());
                    },
                  },
                  {
                    label: 'Reboot',
                    onClick: async () => {
                      const response = await sendRequest(
                        `api/devices/${device.serialNumber}/reboot`,
                        { postData: {} },
                      );
                      updateDeviceLocalData(await response.json());
                    },
                  },
                  {
                    label: 'Delete all user data',
                    onClick: async () => {
                      setWipeFromDevice(device);
                    },
                  },
                ]}
              />
            ),
          },
        ],
      };
    }) ?? [];

  return (
    <Grid container>
      <Grid item xs={12}>
        <Card>
          <CardHeader
            title="Devices"
            action={
              <React.Fragment>
                <IconButton
                  color="secondary"
                  disabled={fetchLoading}
                  onClick={async () => {
                    try {
                      const response = await sendRequest('api/devices', {});
                      setDevices(await response.json());
                    } catch (ex) {}
                  }}
                >
                  <Refresh />
                </IconButton>
                <IconButton
                  color="primary"
                  onClick={() => {
                    setShowClaimDevice(true);
                  }}
                >
                  <Add />
                </IconButton>
              </React.Fragment>
            }
          />

          <DelayedLinearProgress loading={fetchLoading} />

          {!rows?.length ? (
            <EmptyView />
          ) : (
            <div className={classes.tableWrapper}>
              <SortableTable
                columns={columns}
                rows={rows}
                tableProps={{ size: 'small' }}
                defaultSort={{ columnKey: 'name', order: 'asc' }}
                emptyTableText="No devices found"
                disablePagination
              />
            </div>
          )}
          {error && (
            <CardContent>
              <Alert severity="error">
                <Typography variant="body1">{error.message}</Typography>
              </Alert>
            </CardContent>
          )}
        </Card>
        {selectedDevice && (
          <DeviceDialog
            device={selectedDevice}
            open={Boolean(selectedDevice)}
            onClose={() => setSelectedDevice(undefined)}
            onUpdated={updateDeviceLocalData}
          />
        )}

        <ClaimDeviceDialog
          open={Boolean(showClaimDevice)}
          onClose={() => setShowClaimDevice(false)}
          onClaimed={async () => {
            setShowClaimDevice(false);
            try {
              const response = await sendRequest('api/devices', {});
              const jsonResponse = await response.json();
              if (jsonResponse) {
                setDevices(jsonResponse);
              }
            } catch (ex) {}
          }}
        />

        <PushDataToDeviceDialog
          dialogProps={{
            open: openPushDialog,
            onClose: () => {
              setOpenPushDialog(false);
              setSelectedPushDevice(undefined);
            },
          }}
          deviceSerialNumber={selectedPushDevice?.serialNumber}
          onSubmit={pushDataSubmit}
        />

        <PullDataFromDeviceDialog
          dialogProps={{
            open: openPullDialog,
            onClose: () => {
              setOpenPullDialog(false);
              setSelectedPullDevice(undefined);
            },
          }}
          onSubmit={pullDataSubmit}
        />

        <ConfirmDialog
          dialogProps={{ open: Boolean(wipeFromDevice) }}
          title="Wipe user data"
          content={
            <React.Fragment>
              {wipeFromDevice
                ? `You are about to wipe all user data from ${wipeFromDevice.serialNumber}. Are you sure you want to proceed?`
                : 'Select a device to wipe'}
              <Alert
                severity="warning"
                style={{ marginTop: 10, marginBottom: 10 }}
              >
                <Typography variant="body1">
                  This will delete all user info and biometric data from this
                  device.
                </Typography>
              </Alert>
              <Alert severity="info" style={{ marginBottom: 10 }}>
                <Typography variant="body1">
                  If employees are not terminated from Talexio and have T&amp;A
                  enabled, they will be created again automatically.
                </Typography>
              </Alert>
            </React.Fragment>
          }
          typePrompt="WIPE"
          onCancel={() => setWipeFromDevice(undefined)}
          onConfirm={async () => {
            try {
              if (!wipeFromDevice) {
                setWipeFromDevice(undefined);
                return;
              }

              const response = await sendRequest(
                `api/devices/${wipeFromDevice.serialNumber}/wipe`,
                { postData: {} },
              );
              updateDeviceLocalData(await response.json());
              setWipeFromDevice(undefined);
            } catch (ex) {
              setWipeFromDevice(undefined);
              return;
            }
          }}
        />
      </Grid>
    </Grid>
  );

  async function pullDataSubmit(
    attendance: boolean,
    attendancePhoto: boolean,
    bioData: boolean,
    errorLog: boolean,
    idCard: boolean,
    operations: boolean,
  ) {
    if (!selectedPullDevice) {
      return;
    }

    const response = await sendRequest(
      `api/devices/${selectedPullDevice.serialNumber}/check`,
      {
        postData: {
          attendance: attendance ? '1' : '0',
          attendancePhoto: attendancePhoto ? '1' : '0',
          bioData: bioData ? '1' : '0',
          errorLog: errorLog ? '1' : '0',
          idCard: idCard ? '1' : '0',
          operations: operations ? '1' : '0',
        },
      },
    );
    updateDeviceLocalData(await response.json());
  }

  async function pushDataSubmit(
    deviceSerialNumber: string,
    users: boolean,
    fingerPrint: boolean,
    bioData: boolean,
  ) {
    const response = await sendRequest(
      `api/devices/${deviceSerialNumber}/push`,
      {
        postData: {
          users: users ? '1' : '0',
          fingerPrint: fingerPrint ? '1' : '0',
          bioData: bioData ? '1' : '0',
        },
      },
    );
    updateDeviceLocalData(await response.json());
  }

  function updateDeviceLocalData(device: Device) {
    setDevices((d) => {
      const newDevices = d?.slice() ?? [];
      const indexToReplace = newDevices.findIndex(
        (tmp) => tmp.id === device.id,
      );
      if (indexToReplace >= 0) {
        newDevices[indexToReplace] = device;
      }
      return newDevices;
    });
  }
};
