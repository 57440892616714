import {
  ClickAwayListener,
  Grow,
  Hidden,
  IconButton,
  makeStyles,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Typography,
} from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { useGlobalState } from 'App.state';
import classNames from 'classnames';
import { logout } from 'lib';
import * as React from 'react';

const useStyles = makeStyles((theme) => ({
  btnText: {
    marginLeft: theme.spacing(1),
  },
  popperClose: {
    pointerEvents: 'none',
  },
  popperResponsive: {
    [theme.breakpoints.down('md')]: {
      ...theme.mixins.popperResponsive,
    },
  },
  dropdown: {
    ...theme.mixins.dropdown,
  },
  dropdownItem: {
    ...theme.mixins.dropdownItem,
  },
  user: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  infoWrap: {
    display: 'flex',
    flexDirection: 'column',
    textTransform: 'capitalize',
  },
}));

interface State {
  anchorEl?: HTMLElement;
}

export const AccountButton: React.FC = () => {
  const classes = useStyles();
  const [state, setState] = React.useState<State>({});
  const [clockingsAuthUser] = useGlobalState('clockingsAuthUser');

  const { anchorEl } = state;

  const open = Boolean(anchorEl);

  return (
    <React.Fragment>
      <Hidden xsDown>
        <div className={classes.user}>
          <div className={classes.infoWrap}>
            <Typography variant="body1">
              <strong>
                {clockingsAuthUser?.username ?? 'welcome'}{' '}
                {clockingsAuthUser?.client
                  ? `(${clockingsAuthUser?.client})`
                  : ''}
              </strong>
            </Typography>
          </div>

          <IconButton onClick={handleClick}>
            <MoreVertIcon />
          </IconButton>
        </div>
      </Hidden>

      <Popper
        placement="bottom-start"
        open={open}
        className={classNames(classes.popperResponsive, {
          [classes.popperClose]: !open,
        })}
        anchorEl={anchorEl}
        transition
      >
        {({ TransitionProps }) => (
          <ClickAwayListener onClickAway={handleClick}>
            <Grow {...TransitionProps}>
              <Paper className={classes.dropdown}>
                <MenuList role="menu">
                  <MenuItem onClick={logout} className={classes.dropdownItem}>
                    Log out
                  </MenuItem>
                  {clockingsAuthUser?.talexioLinkUrl && (
                    <MenuItem
                      onClick={visitTalexioAccount}
                      className={classes.dropdownItem}
                    >
                      Visit your Talexio Account
                    </MenuItem>
                  )}
                </MenuList>
              </Paper>
            </Grow>
          </ClickAwayListener>
        )}
      </Popper>
    </React.Fragment>
  );

  function handleClick({
    currentTarget,
  }: React.SyntheticEvent<HTMLElement | {}>) {
    setState(({ anchorEl, ...s }) => ({
      ...s,
      anchorEl:
        anchorEl || !(currentTarget instanceof Element)
          ? undefined
          : currentTarget,
    }));
  }

  function visitTalexioAccount() {
    window.open(
      clockingsAuthUser?.talexioLinkUrl,
      '_blank',
      'noopener, noreferrer',
    );
  }
};
