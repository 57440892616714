import {
  Card,
  CardHeader,
  Chip,
  Grid,
  List,
  ListItem,
  makeStyles,
} from '@material-ui/core';
import { Config } from 'config';
import * as React from 'react';
import { DashboardStats } from './components/DashboardStats';

const useStyles = makeStyles((theme) => ({
  link: {
    color: theme.palette.primary.main,
    fontSize: theme.typography.fontSize * 1.5,
  },
  card: {
    marginBottom: theme.spacing(1),
  },
  iframe: {
    height: 1300,
    border: 0,
    padding: 0,
    width: '100%',
  },
}));

export const Dashboard: React.FC = () => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <Grid container>
        <Grid item xs={12} md={6}>
          <Card className={classes.card}>
            <CardHeader
              title="Talexio Clockings Device Manager"
              action={<Chip size="small" label={`v${Config.VERSION}`} />}
            />
          </Card>
          <Card>
            <List>
              <ListItem>
                <a
                  rel="noopener noreferrer"
                  color="primary"
                  target="_blank"
                  className={classes.link}
                  href="https://support.talexio.com/hc/en-us/sections/4407874680978-Release-Notes?source=device-manager"
                >
                  Release Notes
                </a>
              </ListItem>

              <ListItem>
                <a
                  rel="noopener noreferrer"
                  color="primary"
                  target="_blank"
                  className={classes.link}
                  href="https://support.talexio.com/?source=device-manager"
                >
                  Support Centre
                </a>
              </ListItem>

              <ListItem>
                <a
                  rel="noopener noreferrer"
                  color="primary"
                  target="_blank"
                  className={classes.link}
                  href="https://talexio.com/switch/?source=device-manager"
                >
                  Why Switch to Talexio?
                </a>
              </ListItem>

              <ListItem>
                <a
                  rel="noopener noreferrer"
                  color="primary"
                  target="_blank"
                  className={classes.link}
                  href="https://talexio.com/our-mission/?source=device-manager"
                >
                  Our Mission and Values
                </a>
              </ListItem>

              <ListItem>
                <a
                  rel="noopener noreferrer"
                  color="primary"
                  target="_blank"
                  className={classes.link}
                  href="https://talexio.com?source=device-manager"
                >
                  Learn more about Talexio
                </a>
              </ListItem>
            </List>
          </Card>

          {/* <DashboardStats /> */}
        </Grid>
        <Grid item xs={12} md={4}>
          <iframe
            title="Talexio Facebook Feed"
            src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FtalexioHR&tabs=timeline&width=500&height=800&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId=214282495261348"
            width="500"
            height="800"
            style={{
              border: 'none',
              overflow: 'hidden',
              margin: '0px auto',
              maxWidth: '100%',
            }}
            scrolling="no"
            frameBorder="0"
            allowFullScreen={true}
            allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
          ></iframe>
        </Grid>
        <Grid item xs={12} md={2}>
          <DashboardStats />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
