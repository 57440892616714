import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from '@material-ui/core';
import { DialogProps } from '@material-ui/core/Dialog';
import * as React from 'react';

interface Props {
  title: string;
  dialogProps: DialogProps;

  loading?: boolean;
  content?: React.ReactNode;
  noText?: string;
  yesText?: string;
  typePrompt?: string;

  onConfirm?(e: React.MouseEvent<HTMLElement>): void;
  onCancel?(e: React.MouseEvent<HTMLElement>): void;
}

export const ConfirmDialog: React.FC<Props> = ({
  title,
  dialogProps,

  loading = false,
  content,
  noText = 'No',
  yesText = 'Yes',
  typePrompt,
  onConfirm,
  onCancel,
}) => {
  const [typePromptValue, setTypePromptValue] = React.useState('');
  return (
    <Dialog {...dialogProps}>
      <DialogTitle>{title}</DialogTitle>

      <DialogContent>
        {typeof content === 'string' ? (
          <DialogContentText>{content}</DialogContentText>
        ) : (
          content
        )}
        {typePrompt && (
          <TextField
            fullWidth
            autoFocus
            value={typePromptValue}
            onChange={(e) => setTypePromptValue(e.currentTarget.value)}
            placeholder={`Type '${typePrompt}' to confirm this action`}
          />
        )}
      </DialogContent>

      <DialogActions>
        <Button
          onClick={onCancel ?? dialogProps.onClose}
          color="default"
          disabled={loading}
        >
          {noText}
        </Button>

        {onConfirm && (
          <Button
            onClick={(e) => {
              setTypePromptValue('');
              onConfirm(e);

              if (dialogProps.onClose) {
                dialogProps.onClose(e);
              }
            }}
            color="primary"
            disabled={
              loading ||
              (typePrompt !== undefined &&
                typePrompt.toLowerCase() !==
                  typePromptValue.trim().toLowerCase())
            }
            autoFocus
          >
            {yesText}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};
