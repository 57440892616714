export function objectToUrlParams(
  object: { [key: string]: any },
  includeNulls?: boolean,
) {
  return Object.keys(object).reduce<URLSearchParams>((prev, key) => {
    const val = object[key];

    if (typeof val === 'undefined' || (!includeNulls && val === null)) {
      return prev;
    }

    if (val === null) {
      prev.append(key, 'null');
    } else if (Array.isArray(val)) {
      prev.append(key, val.join(','));
    } else {
      prev.append(key, String(val));
    }

    return prev;
  }, new URLSearchParams());
}
