import {
  Button,
  Card,
  CardContent,
  CardHeader,
  makeStyles,
  TextField,
  Typography,
} from '@material-ui/core';
import { Alert, Skeleton } from '@material-ui/lab';
import classNames from 'classnames';
import { SnackbarContext } from 'components';
import { useAudio } from 'hooks/useAudio';
import * as React from 'react';
import { useParams } from 'react-router-dom';
import { useDebounce, useFetch } from '../../hooks';
import { Config } from 'config';

const useStyles = makeStyles((theme) => ({
  card: {
    padding: theme.spacing(0, 0.5, 2, 0.5),
    minWidth: 400,
  },
  cardHeader: {
    background: 'none',
    textAlign: 'center',
    paddingTop: theme.spacing(3),
    paddingBottom: 0,
  },
  cardHeaderTitle: {
    color: theme.palette.primary.main,
  },
  punchButton: {
    display: 'block',
    padding: theme.spacing(3),
    fontSize: 25,
    marginBottom: 10,
    width: '100%',
  },
  punchIn: {
    backgroundColor: '#008080',
    color: '#ffffff',
    '&:hover': {
      backgroundColor: '#008080',
      color: '#ffffff',
    },
    '&:active': {
      backgroundColor: '#008080',
      color: '#ffffff',
    },
  },
  punchOut: {
    backgroundColor: '#CE5757',
    color: '#FFFFFF',
    '&:hover': {
      color: '#444444',
    },
  },
  cancel: {
    fontSize: 18,
    padding: theme.spacing(2),
  },
  pinInput: {
    textAlign: 'center',
    fontSize: 14,
    padding: theme.spacing(3, 2),
  },
  pinContainer: {
    marginBottom: theme.spacing(2),
  },
}));

interface VisitorState {
  visitorAction?: 'in' | 'out';
  visitorId?: number;
  fullName?: string;
  company?: string;
  contactNumber?: string;
  purpose?: string;
}

interface User {
  id: number;
  name: string;
  company: string;
}

export const VisitorKiosk: React.FC = () => {
  const classes = useStyles();
  const useSteps = Config.VISITOR_REGISTRATION_WITH_STEPS;

  const [currentStepIndex, setCurrentStepIndex] = React.useState(0);
  const steps = React.useMemo(
    () => ['full-name', 'company', 'contact', 'purpose'],
    [],
  );

  const currentStep = React.useMemo(() => {
    return steps[currentStepIndex];
  }, [currentStepIndex, steps]);

  const { loading: saveLoading, sendRequest, error } = useFetch();
  const {
    loading: getUsersLoading,
    sendRequest: sendUsersRequest,
  } = useFetch();

  const [errorText, setErrorText] = React.useState<string | undefined>();
  React.useEffect(() => {
    setErrorText(error?.message);
  }, [error, setErrorText]);

  const [form, setForm] = React.useState<VisitorState>({});
  const [search, setSearch] = React.useState<string | undefined>();
  const [users, setUsers] = React.useState<User[]>([]);

  const snackbar = React.useContext(SnackbarContext);
  const { authKey } = useParams<{ authKey: string }>();

  const { toggle: errorAudio } = useAudio('/audio/error.mp3');
  const { toggle: thankyouAudio } = useAudio('/audio/thankyou.mp3');

  const debouncedSearch = useDebounce(search, 1000);

  React.useEffect(() => {
    async function fetchData() {
      setUsers([]);
      if (!debouncedSearch || debouncedSearch.trim().length < 3) {
        return;
      }

      try {
        const response = await sendUsersRequest('api/visitor-lookup', {
          postData: {
            identification: debouncedSearch,
            authKey,
          },
        });
        const jsonResponse = await response.json();
        if (jsonResponse) {
          setUsers(jsonResponse);
        }
      } catch (ex) {}
    }
    fetchData();
  }, [sendUsersRequest, debouncedSearch, setUsers, authKey]);

  return (
    <Card className={classes.card}>
      <CardHeader
        className={classes.cardHeader}
        title={getHeading(form.visitorAction)}
        subheader={getSubHeading(form.visitorAction)}
        classes={{ title: classes.cardHeaderTitle }}
      />

      <CardContent>{getForm()}</CardContent>
    </Card>
  );

  function getForm() {
    if (saveLoading) {
      return <Skeleton variant="rect" style={{ height: 400 }} />;
    }

    if (!form.visitorAction) {
      return (
        <div>
          <Button
            color="default"
            variant="contained"
            className={classNames(classes.punchButton, classes.punchIn)}
            onClick={() => {
              setForm({
                visitorAction: 'in',
                company: undefined,
                contactNumber: undefined,
                fullName: undefined,
                visitorId: undefined,
                purpose: undefined,
              });
            }}
          >
            Check-in
          </Button>
          <Button
            color="default"
            variant="contained"
            className={classNames(classes.punchButton, classes.punchOut)}
            onClick={() => {
              setForm({
                visitorAction: 'out',
                company: undefined,
                contactNumber: undefined,
                fullName: undefined,
                visitorId: undefined,
                purpose: undefined,
              });
            }}
          >
            Check-out
          </Button>
        </div>
      );
    }

    if (form.visitorAction === 'in') {
      return (
        <form
          onSubmit={async (e) => {
            e.stopPropagation();
            e.preventDefault();
            const lastStep = steps.length - 1;
            if (useSteps && currentStepIndex < lastStep) {
              setCurrentStepIndex(currentStepIndex + 1);
              return;
            }
            await submitPunch();
            return false;
          }}
        >
          {(!useSteps || (useSteps && currentStep === 'full-name')) && (
            <div className={classes.pinContainer}>
              <TextField
                variant="outlined"
                label="Full name"
                placeholder="Full name"
                type="text"
                value={form.fullName}
                onChange={(e) => {
                  if (!e.target) {
                    return;
                  }
                  const fullName = e.target.value;
                  setForm((f) => ({
                    ...f,
                    fullName,
                  }));
                }}
                autoComplete="none"
                InputLabelProps={{ shrink: true }}
                inputProps={{
                  className: classes.pinInput,
                }}
                fullWidth
                autoFocus
                required
              />
            </div>
          )}
          {(!useSteps || (useSteps && currentStep === 'company')) && (
            <div className={classes.pinContainer}>
              <TextField
                variant="outlined"
                label="Company/Organisation"
                placeholder="Company/Organisation"
                type="text"
                value={form.company}
                onChange={(e) => {
                  if (!e.target) {
                    return;
                  }
                  const company = e.target.value;
                  setForm((f) => ({
                    ...f,
                    company,
                  }));
                }}
                autoComplete="none"
                InputLabelProps={{ shrink: true }}
                inputProps={{
                  className: classes.pinInput,
                }}
                autoFocus={useSteps}
                fullWidth
                required
              />
            </div>
          )}
          {(!useSteps || (useSteps && currentStep === 'contact')) && (
            <div className={classes.pinContainer}>
              <TextField
                variant="outlined"
                label="Contact number"
                placeholder="Contact number"
                type="text"
                value={form.contactNumber}
                autoFocus={useSteps}
                onChange={(e) => {
                  if (!e.target) {
                    return;
                  }
                  const contactNumber = e.target.value;
                  setForm((f) => ({
                    ...f,
                    contactNumber,
                  }));
                }}
                autoComplete="none"
                InputLabelProps={{ shrink: true }}
                inputProps={{
                  className: classes.pinInput,
                }}
                fullWidth
                required
              />
            </div>
          )}
          {(!useSteps || (useSteps && currentStep === 'purpose')) && (
            <div className={classes.pinContainer}>
              <TextField
                variant="outlined"
                label="Purpose of visit"
                placeholder="Purpose of visit"
                autoFocus={useSteps}
                type="text"
                value={form.purpose}
                onChange={(e) => {
                  if (!e.target) {
                    return;
                  }
                  const purpose = e.target.value;
                  setForm((f) => ({
                    ...f,
                    purpose,
                  }));
                }}
                autoComplete="none"
                InputLabelProps={{ shrink: true }}
                inputProps={{
                  className: classes.pinInput,
                }}
                fullWidth
                required
              />
            </div>
          )}
          {errorText && (
            <Alert severity="error" style={{ marginBottom: 8 }}>
              <Typography variant="body1">{errorText}</Typography>
            </Alert>
          )}

          {getButtons()}
        </form>
      );
    }

    function getButtons() {
      if (useSteps) {
        return (
          <div>
            {currentStepIndex === steps.length - 1 ? (
              <Button
                variant="contained"
                color="default"
                type="submit"
                className={classNames(classes.punchButton, classes.punchIn)}
              >
                Submit
              </Button>
            ) : (
              <Button
                variant="contained"
                color="default"
                type="submit"
                className={classNames(classes.punchButton, classes.punchIn)}
              >
                Next
              </Button>
            )}
            {currentStepIndex > 0 && (
              <Button
                variant="contained"
                color="default"
                className={classNames(classes.punchButton, classes.cancel)}
                onClick={() => {
                  if (currentStepIndex === 0) {
                    return;
                  }
                  setCurrentStepIndex(currentStepIndex - 1);
                }}
              >
                Back
              </Button>
            )}
            <Button
              variant="contained"
              color="default"
              className={classNames(classes.punchButton, classes.cancel)}
              onClick={() => onCancel()}
            >
              Cancel
            </Button>
          </div>
        );
      }
      return (
        <div>
          <Button
            variant="contained"
            color="default"
            type="submit"
            className={classNames(classes.punchButton, classes.punchIn)}
          >
            Submit
          </Button>
          <Button
            variant="contained"
            color="default"
            className={classNames(classes.punchButton, classes.cancel)}
            onClick={() => onCancel}
          >
            Cancel
          </Button>
        </div>
      );
    }

    function onCancel() {
      if (useSteps && currentStepIndex > 0) {
        if (
          !window.confirm('Are you sure you want to cancel your registration?')
        ) {
          return;
        }
      }
      setForm({});
      setSearch(undefined);
      setUsers([]);
      setErrorText(undefined);
      setCurrentStepIndex(0);
    }

    return (
      <form
        onSubmit={async (e) => {
          e.stopPropagation();
          e.preventDefault();
          await submitPunch();
          return false;
        }}
      >
        <div className={classes.pinContainer}>
          <TextField
            variant="outlined"
            label="Identification"
            placeholder="Your name, pin or contact number"
            type="text"
            value={search}
            onChange={(e) => {
              if (!e.target) {
                return;
              }
              const identification = e.target.value;
              setSearch(identification);
              setForm((f) => ({
                ...f,
                visitorId: undefined,
              }));
            }}
            autoComplete="none"
            InputLabelProps={{ shrink: true }}
            inputProps={{
              className: classes.pinInput,
            }}
            fullWidth
            autoFocus
            required
          />
        </div>

        {getUsersLoading ? (
          <Skeleton style={{ height: 200, marginBottom: 8 }} variant="rect" />
        ) : (
          users.map((user) => (
            <div
              style={{
                padding: 20,
                marginBottom: 4,
                border: '1px solid #ddd',
                cursor: 'pointer',
                display: 'flex',
              }}
              key={`user_selection_${user.id}`}
              onClick={() => {
                setForm((f) => ({
                  ...f,
                  visitorId: user.id,
                }));
              }}
            >
              <input
                type="radio"
                name="visitor_id"
                value={user.id}
                checked={form.visitorId === user.id}
                style={{ marginRight: 16 }}
              />
              <div>
                <Typography variant="h6">{user.name}</Typography>
                <Typography variant="body1">{user.company}</Typography>
              </div>
            </div>
          ))
        )}
        {errorText && (
          <Alert severity="error" style={{ marginBottom: 8 }}>
            <Typography variant="body1">{errorText}</Typography>
          </Alert>
        )}
        <Button
          variant="contained"
          color="default"
          type="submit"
          className={classNames(classes.punchButton, classes.punchOut)}
          disabled={!form.visitorId}
        >
          Submit
        </Button>
        <Button
          variant="contained"
          color="default"
          className={classNames(classes.punchButton, classes.cancel)}
          onClick={() => {
            setForm({});
            setErrorText(undefined);
            setSearch(undefined);
            setUsers([]);
          }}
        >
          Cancel
        </Button>
      </form>
    );
  }

  async function submitPunch() {
    try {
      setErrorText(undefined);
      await sendRequest('api/visitors', {
        postData: {
          ...form,
          authKey,
        },
      });

      thankyouAudio();
      snackbar.success('All done!');
      setForm({});
      setCurrentStepIndex(0);
    } catch (error) {
      errorAudio();
      snackbar.error(error);
    } finally {
      setSearch(undefined);
    }
  }
};

function getHeading(visitorAction?: 'in' | 'out') {
  switch (visitorAction) {
    case 'in':
      return 'Visitor registration';
    case 'out':
      return 'Check out';
  }
  return 'Welcome';
}

function getSubHeading(visitorAction?: 'in' | 'out') {
  switch (visitorAction) {
    case 'in':
      return 'Please complete your visitor registration below.';
    case 'out':
      return 'Please complete the check-out form below.';
  }
  return 'We hope you enjoy your visit.';
}
