import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Checkbox,
  FormControlLabel,
  makeStyles,
  TextField,
  Typography,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { DelayedLinearProgress, SnackbarContext } from 'components';
import { Config } from 'config';
import Cookies from 'js-cookie';
import { isCheckboxChangeEvent } from 'lib/isChangeEvent';
import * as React from 'react';
import { useGlobalState } from '../../App.state';
import { useFetch } from '../../hooks';

const useStyles = makeStyles((theme) => ({
  card: {
    padding: theme.spacing(0, 0.5, 2, 0.5),
  },
  cardActions: {
    justifyContent: 'space-between',
  },
  cardHeader: {
    background: 'none',
    textAlign: 'center',
    paddingTop: theme.spacing(3),
    paddingBottom: 0,
  },
  cardHeaderTitle: {
    color: theme.palette.primary.main,
  },
  ssoCard: {
    padding: theme.spacing(2, 5, 2, 5),
    textAlign: 'center',
  },
}));

interface LoginInput {
  authKey: string;
  username: string;
  password: string;
  rememberMe: boolean;
  mfaCode?: string;
}

interface AuthResponse {
  token: string;
  username: string;
  client?: string;
  id: string | number;
  talexioLinkUrl?: string;
}

export const Login: React.FC = () => {
  const classes = useStyles();

  const { loading: fetchLoading, sendRequest, error } = useFetch();

  const [form, setForm] = React.useState<LoginInput>(getInitialForm());
  const snackbar = React.useContext(SnackbarContext);

  const [, setClockingsAuthUser] = useGlobalState('clockingsAuthUser');

  return (
    <Card className={classes.card}>
      <form onSubmit={submit}>
        <CardHeader
          className={classes.cardHeader}
          title="Talexio Device Manager"
          subheader="Use your Device Manager admin account"
          classes={{ title: classes.cardHeaderTitle }}
        />

        <CardContent>
          {!Config.DEFAULT_CLIENT_AUTHKEY && (
            <TextField
              fullWidth
              margin="normal"
              label="Client auth key"
              name="authKey"
              onChange={handleChange}
              type="text"
              autoComplete="authKey"
              required
            />
          )}

          <TextField
            fullWidth
            margin="normal"
            label="Username"
            name="username"
            onChange={handleChange}
            type="text"
            autoComplete="username"
            required
          />

          <TextField
            fullWidth
            margin="normal"
            label="Password"
            name="password"
            onChange={handleChange}
            type="password"
            autoComplete="current-password"
            required
          />

          <FormControlLabel
            control={<Checkbox name="rememberMe" onChange={handleChange} />}
            label="Remember me"
          />

          {error && (
            <Alert severity="error">
              <Typography variant="body1">{error.message}</Typography>
            </Alert>
          )}
        </CardContent>

        <CardActions className={classes.cardActions}>
          <Button
            type="submit"
            size="medium"
            color="primary"
            variant="contained"
          >
            Device Manager Login
          </Button>
        </CardActions>
        <DelayedLinearProgress loading={fetchLoading} />
      </form>
    </Card>
  );

  async function submit(e: React.FormEvent<HTMLFormElement>) {
    e.stopPropagation();
    e.preventDefault();
    doLogin();
  }

  async function doLogin() {
    try {
      const { authKey, username, password, rememberMe } = form;

      if (!authKey || !username || !password) {
        return;
      }

      const response = await sendRequest('api/login', {
        postData: {
          authKey,
          username,
          password,
        },
      });

      const authResponse: AuthResponse = await response.json();

      if (rememberMe) {
        Cookies.set('clockingsAuthUser', authResponse, {
          expires: 30,
          path: '/',
        });
      }
      setClockingsAuthUser(authResponse);
    } catch (error) {
      snackbar.error(error);
    }
  }

  function handleChange(
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) {
    const { name, value } = e.currentTarget;

    const newVal = isCheckboxChangeEvent(e) ? e.currentTarget.checked : value;

    setForm((f) => ({ ...f, [name]: newVal }));
  }

  function getInitialForm() {
    return {
      authKey: Config.DEFAULT_CLIENT_AUTHKEY,
      username: '',
      password: '',
      rememberMe: false,
    };
  }
};
