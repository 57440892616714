import {
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';
import { DialogProps } from '@material-ui/core/Dialog';
import { FormDialog } from 'components';
import { useFetch, usePrevious } from 'hooks';
import { Device } from 'lib/Model/Device';
import * as React from 'react';

interface Props {
  dialogProps: DialogProps;
  deviceSerialNumber?: string;
  onSubmit: (
    selectedDeviceSerialNumber: string,
    users: boolean,
    fingerPrint: boolean,
    bioData: boolean,
  ) => void;
}

export const PushDataToDeviceDialog: React.FC<Props> = ({
  dialogProps,
  deviceSerialNumber,
  onSubmit,
}) => {
  const [pushUser, setPushUser] = React.useState(false);
  const [pushFingerPrint, setPushFingerPrint] = React.useState(false);
  const [pushBioData, setPushBioData] = React.useState(false);
  const [devices, setDevices] = React.useState<Device[] | undefined>();
  const [selectedDeviceSn, setSelectedDeviceSn] = React.useState<
    string | undefined
  >();
  const { sendRequest } = useFetch();

  React.useEffect(() => {
    async function fetchData() {
      try {
        const response = await sendRequest('api/devices', {});
        const jsonResponse = await response.json();
        if (jsonResponse) {
          setDevices(jsonResponse);
        }
      } catch (ex) {}
    }
    fetchData();
  }, [setDevices, sendRequest]);

  const prevOpen = usePrevious(dialogProps.open);
  const justOpened = !prevOpen;

  React.useEffect(() => {
    if (justOpened) {
      setPushUser(false);
      setPushFingerPrint(false);
      setPushBioData(false);
      setSelectedDeviceSn(deviceSerialNumber);
    }
  }, [justOpened, deviceSerialNumber, setSelectedDeviceSn]);

  return (
    <React.Fragment>
      <FormDialog
        title="Push data to device"
        submitText="Push"
        onSubmit={(e) => {
          if (
            !selectedDeviceSn ||
            (!pushUser && !pushFingerPrint && !pushBioData)
          ) {
            return;
          }

          onSubmit(selectedDeviceSn, pushUser, pushFingerPrint, pushBioData);

          if (dialogProps.onClose) {
            dialogProps.onClose(e);
          }
        }}
        dialogProps={{
          maxWidth: 'sm',
          fullWidth: true,
          ...dialogProps,
        }}
      >
        <Typography variant="body1">
          Data is automatically synced to devices. Using this function you can
          force a full push of all data back to the device.
        </Typography>

        <div style={{ margin: '20px 0px' }}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-required-label">
              Device
            </InputLabel>
            <Select
              value={selectedDeviceSn}
              onChange={(e) => {
                setSelectedDeviceSn(
                  e.target.value ? (e.target.value as string) : undefined,
                );
              }}
              displayEmpty
              inputProps={{ 'aria-label': 'Without label' }}
              required
            >
              <MenuItem value="all">All devices</MenuItem>
              {devices &&
                devices.map((device) => (
                  <MenuItem key={device.id} value={device.serialNumber}>
                    {device.name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </div>

        <FormControlLabel
          control={
            <Checkbox
              name="pushUser"
              checked={pushUser ?? false}
              onChange={(e) => setPushUser(e.target.checked)}
            />
          }
          label="User data"
        />
        <FormControlLabel
          control={
            <Checkbox
              name="pushFingerPrint"
              checked={pushFingerPrint ?? false}
              onChange={(e) => setPushFingerPrint(e.target.checked)}
            />
          }
          label="Fingerprints"
        />
        <FormControlLabel
          control={
            <Checkbox
              name="pushBioData"
              checked={pushBioData ?? false}
              onChange={(e) => setPushBioData(e.target.checked)}
            />
          }
          label="Biodata (Face, Palm)"
        />
      </FormDialog>
    </React.Fragment>
  );
};
