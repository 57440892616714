import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import { SharedPunchClock } from 'views/SharedPunchClock';
import { SharedPunchClockSetClient } from 'views/SharedPunchClock/SharedPunchClockSetClient';
import brandLogo from '../App/assets/talexio-logo.png';
import { useStyles } from './styles';
import { VisitorKiosk } from 'views/VisitorKiosk/VisitorKiosk';

export const GuestLayout: React.FC = () => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <div className={classes.container}>
        <div className={classes.authContainer}>
          <React.Fragment>
            <div className={classes.logoContainer}>
              <img src={brandLogo} alt="Talexio" className={classes.logo} />
            </div>

            <div>
              <Switch>
                <Route path="/:authKey([a-zA-Z0-9:]+)/punch-clock">
                  <SharedPunchClock />
                </Route>
                <Route path="/:authKey([a-zA-Z0-9:]+)/visitors">
                  <VisitorKiosk />
                </Route>
                <Route path="/punch-clock">
                  <SharedPunchClockSetClient />
                </Route>
              </Switch>
            </div>
          </React.Fragment>
        </div>
      </div>
    </React.Fragment>
  );
};
