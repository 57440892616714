import {
  Button,
  Card,
  CardContent,
  CardHeader,
  makeStyles,
  TextField,
} from '@material-ui/core';
import * as React from 'react';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  card: {
    padding: theme.spacing(0, 0.5, 2, 0.5),
    minWidth: 400,
  },
  cardHeader: {
    background: 'none',
    textAlign: 'center',
    paddingTop: theme.spacing(3),
    paddingBottom: 0,
  },
  cardHeaderTitle: {
    color: theme.palette.primary.main,
  },
  punchButton: {
    display: 'block',
    padding: theme.spacing(3),
    fontSize: 25,
    marginBottom: 10,
    width: '100%',
  },
  pinInput: {
    textAlign: 'center',
    fontSize: 30,
    padding: theme.spacing(1, 0),
  },
  pinContainer: {
    padding: theme.spacing(4, 0),
    marginBottom: theme.spacing(2),
  },
}));

interface FormState {
  authKey?: string;
}

export const SharedPunchClockSetClient: React.FC = () => {
  const classes = useStyles();

  const [form, setForm] = React.useState<FormState>({});
  const history = useHistory();
  return (
    <Card className={classes.card}>
      <CardHeader
        className={classes.cardHeader}
        title={'Talexio Punch Clock'}
        subheader={'Set client - first time setup.'}
        classes={{ title: classes.cardHeaderTitle }}
      />

      <CardContent>{getForm()}</CardContent>
    </Card>
  );

  function getForm() {
    return (
      <form
        onSubmit={(e) => {
          if (!form.authKey) {
            return;
          }
          e.stopPropagation();
          e.preventDefault();
          history.push(`${form.authKey}/punch-clock`);
          return false;
        }}
      >
        <div className={classes.pinContainer}>
          <TextField
            variant="outlined"
            label="Web device serial number"
            required
            type="text"
            value={form.authKey}
            onChange={(e) => {
              if (!e.target) {
                return;
              }
              const authKey = e.target.value;
              setForm((f) => ({
                ...f,
                authKey,
              }));
            }}
            autoComplete="none"
            InputLabelProps={{ shrink: true }}
            inputProps={{
              className: classes.pinInput,
            }}
            fullWidth
            autoFocus
          />
        </div>
        <Button
          variant="contained"
          type="submit"
          color="primary"
          className={classes.punchButton}
        >
          Submit Setup
        </Button>
      </form>
    );
  }
};
