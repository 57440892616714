import * as React from 'react';
import {
  Grid,
  Card,
  Button,
  CardHeader,
  IconButton,
  CardContent,
  Typography,
} from '@material-ui/core';
import { FtpConfig } from 'lib/Model/FtpConfig';
import { DelayedLinearProgress, EmptyView, SnackbarContext } from 'components';
import { formatDistanceToNow, parse } from 'date-fns';
import { useStyles } from './styles';
import { Refresh } from '@material-ui/icons';
import { FtpConfigDialog } from './components';
import { useFetch } from 'hooks';
import { SortableTable } from 'components/SortableTable';
import { SortableTableHeader } from 'components/SortableTable/components';
import { Alert } from '@material-ui/lab';
import { DateTimeFormat } from 'config';
import { PopperDropdownMenu } from 'components/PopperDropdownMenu';
import { useLocation } from 'react-router-dom';

export const FtpConfigs: React.FC = () => {
  const classes = useStyles();

  const snackbar = React.useContext(SnackbarContext);
  const [ftpConfigs, setFtpConfigs] = React.useState<FtpConfig[] | undefined>();
  const [selectedFtpConfig, setSelectedFtpConfig] = React.useState<
    FtpConfig | undefined
  >();
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const { loading: fetchLoading, sendRequest, error } = useFetch();
  const location = useLocation();

  React.useEffect(() => {
    async function fetchData() {
      try {
        const response = await sendRequest('api/ftp-configs', {});
        const jsonResponse = await response.json();
        if (jsonResponse) {
          setFtpConfigs(jsonResponse);
        }
      } catch (ex) {}
    }
    fetchData();
  }, [setFtpConfigs, sendRequest]);

  const columns: SortableTableHeader[] = [
    { key: 'host', label: 'Host', sortable: true },
    { key: 'username', label: 'Username', sortable: true },
    { key: 'created', label: 'Created', sortable: true },
    { key: 'modified', label: 'Modified', sortable: true },
    { key: 'actions', label: '', sortable: false },
  ];

  const rows =
    ftpConfigs?.map((ftpConfig) => {
      const createdOn = parse(
        ftpConfig.createdOn,
        DateTimeFormat.GQL,
        new Date(),
      );
      const modifiedOn = ftpConfig.modifiedOn
        ? parse(ftpConfig.modifiedOn, DateTimeFormat.GQL, new Date())
        : undefined;
      return {
        key: ftpConfig.id.toString(),
        cells: [
          {
            key: 'host',
            display: (
              <Button
                variant="text"
                color="primary"
                className={classes.primaryLink}
                onClick={() => {
                  setSelectedFtpConfig(ftpConfig);
                  setDialogOpen(true);
                }}
              >
                {ftpConfig.host}
              </Button>
            ),
            sortValue: ftpConfig.host,
          },
          {
            key: 'username',
            display: ftpConfig.username,
            sortValue: ftpConfig.username,
          },
          {
            key: 'created',
            display: formatDistanceToNow(createdOn),
            sortValue: createdOn.getTime() ?? -1,
          },
          {
            key: 'modified',
            display: !modifiedOn ? 'N/A' : formatDistanceToNow(modifiedOn),
            sortValue: modifiedOn?.getTime() ?? -1,
          },
          {
            key: 'actions',
            display: (
              <PopperDropdownMenu
                menuItems={[
                  {
                    label: 'Run upload clockings now',
                    onClick: async () => {
                      if (
                        !window.confirm(
                          'Are you sure you want to push clockings now?',
                        )
                      ) {
                        return;
                      }

                      const searchParams = new URLSearchParams(location.search);
                      const forceFromDate: string | undefined =
                        searchParams.get('forceFromDate') ?? undefined;
                      const response = await sendRequest(
                        `api/ftp-configs/${ftpConfig.id}/run-push`,
                        {
                          postData: {
                            forceFromDate,
                          },
                        },
                      );
                      const jsonResponse = await response.json();
                      if (jsonResponse) {
                        snackbar.success(`Upload complete`);
                      } else {
                        snackbar.error(`Upload failed or nothing to upload.`);
                      }
                    },
                  },
                  {
                    label: 'Run download employees now',
                    onClick: async () => {
                      if (
                        !window.confirm(
                          'Are you sure you want to download employee data now?',
                        )
                      ) {
                        return;
                      }
                      const response = await sendRequest(
                        `api/ftp-configs/${ftpConfig.id}/run-pull`,
                        { postData: {} },
                      );
                      const jsonResponse = await response.json();
                      if (jsonResponse) {
                        snackbar.success(
                          `Import complete: ${jsonResponse.newCount} new, ${jsonResponse.updatedCount} updated, ${jsonResponse.unchangedCount} unchanged.`,
                        );
                      } else {
                        snackbar.error(`Download failed.`);
                      }
                    },
                  },
                  {
                    label: 'Run terminate employees now',
                    onClick: async () => {
                      if (
                        !window.confirm(
                          'Are you sure you want to delete terminated employees data now?',
                        )
                      ) {
                        return;
                      }
                      const response = await sendRequest(
                        `api/ftp-configs/${ftpConfig.id}/run-pull-terminations`,
                        { postData: {} },
                      );
                      const jsonResponse = await response.json();
                      if (jsonResponse) {
                        snackbar.success(
                          `Delete complete: ${jsonResponse.deletedCount} deleted, ${jsonResponse.unchangedCount} unchanged.`,
                        );
                      } else {
                        snackbar.error(`Download failed.`);
                      }
                    },
                  },
                  {
                    label: 'Delete',
                    onClick: async () => {
                      if (
                        !window.confirm(
                          'Are you sure you want to delete this FTP config?',
                        )
                      ) {
                        return;
                      }
                      await sendRequest(
                        `api/ftp-configs/${ftpConfig.id}/delete`,
                        { postData: {} },
                      );
                      try {
                        const response = await sendRequest(
                          'api/ftp-configs',
                          {},
                        );
                        setFtpConfigs(await response.json());
                      } catch (ex) {}
                    },
                  },
                ]}
              />
            ),
          },
        ],
      };
    }) ?? [];

  return (
    <Grid container>
      <Grid item xs={12}>
        <Card>
          <CardHeader
            title="FTP Configs"
            action={
              <React.Fragment>
                <Button
                  color="primary"
                  onClick={() => {
                    setDialogOpen(true);
                  }}
                >
                  Add FTP Config
                </Button>
                <IconButton
                  color="secondary"
                  disabled={fetchLoading}
                  onClick={async () => {
                    try {
                      const response = await sendRequest('api/ftp-configs', {});
                      setFtpConfigs(await response.json());
                    } catch (ex) {}
                  }}
                >
                  <Refresh />
                </IconButton>
              </React.Fragment>
            }
          />

          <DelayedLinearProgress loading={fetchLoading} />

          {!rows?.length ? (
            <EmptyView />
          ) : (
            <React.Fragment>
              <SortableTable
                columns={columns}
                rows={rows}
                tableProps={{ size: 'small' }}
                defaultSort={{ columnKey: 'name', order: 'asc' }}
                loading={fetchLoading}
                emptyTableText="No FTP configs found"
              />
              <Alert severity="info">
                When triggering <em>'Run upload clockings now'</em>, you can
                push past clockings using <em>?forceFromDate=YYYY-MM-dd</em> on
                this page. Such an action may have undesired consequences.
              </Alert>
            </React.Fragment>
          )}
          {error && (
            <CardContent>
              <Alert severity="error">
                <Typography variant="body1">{error.message}</Typography>
              </Alert>
            </CardContent>
          )}
        </Card>
        {dialogOpen && (
          <FtpConfigDialog
            ftpConfig={selectedFtpConfig}
            open={dialogOpen}
            onClose={async () => {
              setSelectedFtpConfig(undefined);
              setDialogOpen(false);
              try {
                const response = await sendRequest('api/ftp-configs', {});
                setFtpConfigs(await response.json());
              } catch (ex) {}
            }}
          />
        )}
      </Grid>
    </Grid>
  );
};
