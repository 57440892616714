/**
 * Turns "myPascalCase" to "My Pascal Case"
 * @param input The string in pascal case
 */
export function pascalToWords(input: string): string {
  return (
    input
      // Split when an upper case letter follows a lower case letter
      .replace(/([a-z])([A-Z])/g, '$1 $2')
      // Set first letter of input to upper case
      .replace(/^./, (c) => c.toUpperCase())
  );
}

/**
 * Turns "my_underscored_words" to "My Underscored Words"
 * @param input The string in underscored format
 */
export function underscoredToWords(input: string): string {
  const frags = input.split('_');
  for (let i = 0; i < frags.length; i++) {
    frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
  }
  return frags.join(' ');
}

/**
 * Capitalises strings
 * @param input the string to capitalise
 */
export function capitalise(input: string): string {
  const lowercase = input.toLowerCase();

  return lowercase.charAt(0).toUpperCase() + lowercase.slice(1);
}

// Returns a function, that, as long as it continues to be invoked, will not
// be triggered. The function will be called after it stops being called for
// N milliseconds. If `immediate` is passed, trigger the function on the
// leading edge, instead of the trailing.
export function debounce<T extends (...args: any) => any>(
  fn: T,
  wait: number,
  immediate?: boolean,
): T {
  let timeout: number | undefined;

  // @ts-ignore
  return (...args: Parameters<T>): ReturnType<T> => {
    const callNow = immediate && !timeout;

    window.clearTimeout(timeout);
    timeout = window.setTimeout(later, wait);

    if (callNow) {
      fn(...args);
    }

    function later() {
      timeout = undefined;

      if (!immediate) {
        fn(...args);
      }
    }
  };
}

export function isFunction(fn: any): fn is Function {
  return typeof fn === 'function';
}
