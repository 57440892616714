import * as React from 'react';
import {
  Grid,
  Card,
  Button,
  CardHeader,
  Avatar,
  IconButton,
  CardContent,
  Typography,
  Checkbox,
  Badge,
  TextField,
  Radio,
} from '@material-ui/core';
import { Employee } from 'lib/Model/Employee';
import {
  ConfirmDialog,
  DelayedLinearProgress,
  EmptyView,
  SnackbarContext,
} from 'components';
import { formatDistanceToNow, parse } from 'date-fns';
import { useStyles } from './styles';
import {
  Face,
  Fingerprint,
  CreditCard,
  VpnKey,
  PanTool,
  Refresh,
} from '@material-ui/icons';
import { EmployeeDevicesDialog, EmployeeDialog } from './components';
import { useFetch } from 'hooks';
import { SortableTable } from 'components/SortableTable';
import { SortableTableHeader } from 'components/SortableTable/components';
import { Alert } from '@material-ui/lab';
import { Config, DateTimeFormat } from 'config';
import { Download } from 'lib';
import { PopperDropdownMenu } from 'components/PopperDropdownMenu';
import { PushDataToDeviceDialog } from 'views/Devices/components';

export const Employees: React.FC = () => {
  const classes = useStyles();

  const [employees, setEmployees] = React.useState<Employee[] | undefined>();
  const [selectedEmployee, setSelectedEmployee] = React.useState<
    Employee | undefined
  >();
  const [deleteEmployee, setDeleteEmployee] = React.useState<
    Employee | undefined
  >();
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [devicesDialogOpen, setDevicesDialogOpen] = React.useState(false);
  const [openPushDialog, setOpenPushDialog] = React.useState(false);

  const [visitorToggle, setVisitorToggle] = React.useState<
    'employees' | 'visitors' | 'both'
  >('employees');

  const snackbar = React.useContext(SnackbarContext);
  const [selectedEmployees, setSelectedEmployees] = React.useState<number[]>(
    [],
  );
  const [search, setSearch] = React.useState('');

  const { loading: fetchLoading, sendRequest, error } = useFetch();
  const { sendRequest: downloadRequest, error: downloadError } = useFetch();
  const {
    loading: uploadLoading,
    sendRequest: uploadRequest,
    error: uploadError,
  } = useFetch();

  React.useEffect(() => {
    async function fetchData() {
      try {
        const params = {
          employeesOnly: '0',
          visitorsOnly: '0',
        };
        if (visitorToggle === 'employees') {
          params.employeesOnly = '1';
        } else if (visitorToggle === 'visitors') {
          params.visitorsOnly = '1';
        }
        const queryParams = new URLSearchParams(params);
        const response = await sendRequest(`api/users?${queryParams}`, {});
        const jsonResponse = await response.json();
        if (jsonResponse) {
          setEmployees(jsonResponse);
        }
      } catch (ex) {}
    }
    fetchData();
  }, [setEmployees, sendRequest, visitorToggle]);

  const columns: SortableTableHeader[] = [
    {
      key: 'select',
      label: (
        <Badge badgeContent={selectedEmployees.length} color="secondary">
          <Checkbox
            checked={selectedEmployees.length > 0}
            indeterminate={
              employees &&
              selectedEmployees.length > 0 &&
              selectedEmployees.length !== employees.length
            }
            onChange={({ target }) => {
              setSelectedEmployees((curForm) => {
                if (!employees) {
                  return [];
                }
                const filteredEmployees = employees
                  .filter(employeeFilter)
                  .map((y) => y.id);
                if (curForm.length < filteredEmployees.length) {
                  return filteredEmployees;
                }
                return [];
              });
            }}
          />
        </Badge>
      ),
      props: { style: { width: 50, textAlign: 'center' } },
      sortable: false,
    },
    {
      key: 'avatar',
      label: '',
      props: { style: { width: 50, textAlign: 'center' } },
      sortable: false,
    },
    { key: 'empNumber', label: 'Emp. No.', sortable: true },
    { key: 'name', label: 'Name', sortable: true },
    { key: 'role', label: 'Role', sortable: true },
    { key: 'departmentCode', label: 'Department Code', sortable: true },
    { key: 'departmentName', label: 'Department Name', sortable: true },
    { key: 'biometrics', label: 'Biometrics', sortable: true },
    { key: 'created', label: 'Created', sortable: true },
    { key: 'modified', label: 'Modified', sortable: true },
    { key: 'actions', label: '', sortable: false },
  ];

  const employeeFilter = (f: Employee) => {
    if (!search.trim().length || !searchTerms.length) {
      return true;
    }

    if (searchTerms.length === 1) {
      // When there is a single term, do not match for exact matches
      const singleTerm = searchTerms[0];
      return (
        f.employeeNumber.toString().toLowerCase().indexOf(singleTerm) >= 0 ||
        f.name.toLowerCase().indexOf(singleTerm) >= 0 ||
        (f.departmentCode &&
          f.departmentCode.toLowerCase().indexOf(singleTerm) >= 0) ||
        (f.departmentName &&
          f.departmentName.toLowerCase().indexOf(singleTerm) >= 0)
      );
    }

    // If we have a comma, we will search for exact matches
    return (
      searchTerms.includes(f.employeeNumber.toString().toLowerCase().trim()) ||
      searchTerms.includes(f.name.toLowerCase().trim())
    );
  };

  const searchTerms = search.toLowerCase().trim().split(',');
  const rows =
    employees?.filter(employeeFilter).map((employee) => {
      const createdOn = parse(
        employee.createdOn,
        DateTimeFormat.GQL,
        new Date(),
      );
      const modifiedOn = employee.modifiedOn
        ? parse(employee.modifiedOn, DateTimeFormat.GQL, new Date())
        : undefined;
      return {
        key: employee.id.toString(),
        cells: [
          {
            key: 'select',
            display: (
              <Checkbox
                checked={selectedEmployees.indexOf(employee.id) >= 0}
                onChange={({ target }) => {
                  setSelectedEmployees((s) => {
                    const newEmployees = s.slice();
                    const currentPos = s.indexOf(employee.id);
                    if (currentPos >= 0) {
                      newEmployees.splice(currentPos, 1);
                    } else {
                      newEmployees.push(employee.id);
                    }
                    return newEmployees;
                  });
                }}
              />
            ),
            sortValue: employee.id,
          },
          {
            key: 'avatar',
            display: (
              <Avatar
                src={
                  employee.userPicFileName
                    ? `${Config.API_BASE_URL}${employee.userPicFileName}`
                    : undefined
                }
              />
            ),
            sortValue: employee.id,
          },
          {
            key: 'empNumber',
            display: employee.employeeNumber?.toString() ?? '',
            sortValue: employee.employeeNumber,
          },
          {
            key: 'fullName',
            display: (
              <Button
                variant="text"
                color="primary"
                className={classes.primaryLink}
                onClick={() => {
                  setSelectedEmployee(employee);
                  setDialogOpen(true);
                }}
              >
                {employee.name}
              </Button>
            ),
            sortValue: employee.name,
          },
          {
            key: 'role',
            display: employee.isVisitor ? 'Visitor' : employee.role,
            sortValue: employee.privilege,
          },
          {
            key: 'departmentCode',
            display: employee.departmentCode,
            sortValue: employee.departmentCode,
          },
          {
            key: 'departmentName',
            display: employee.departmentName,
            sortValue: employee.departmentName,
          },
          {
            key: 'biometrics',
            display: employee.isVisitor ? (
              ''
            ) : (
              <React.Fragment>
                <Face
                  color={Boolean(employee.faceData) ? 'secondary' : 'disabled'}
                  style={{ margin: '0px 5px' }}
                />
                <VpnKey
                  color={Boolean(employee.password) ? 'secondary' : 'disabled'}
                  style={{ margin: '0px 5px' }}
                />
                <Fingerprint
                  color={
                    (employee.fingerPrints?.length ?? 0) > 0
                      ? 'secondary'
                      : 'disabled'
                  }
                  style={{ margin: '0px 5px' }}
                />
                <CreditCard
                  color={
                    Boolean(employee.cardNumber) ? 'secondary' : 'disabled'
                  }
                  style={{ margin: '0px 5px' }}
                />
                <PanTool
                  color={Boolean(employee.palmData) ? 'secondary' : 'disabled'}
                  style={{ margin: '0px 5px' }}
                />
              </React.Fragment>
            ),
            sortValue: `${Boolean(employee.faceData) ? '1' : 0}${
              Boolean(employee.password) ? '1' : 0
            }${employee.fingerPrints?.length ?? 0 ? '1' : 0}${
              Boolean(employee.cardNumber) ? '1' : 0
            }${Boolean(employee.palmData) ? '1' : 0}`,
          },
          {
            key: 'created',
            display: formatDistanceToNow(createdOn),
            sortValue: createdOn.getTime() ?? -1,
          },
          {
            key: 'modified',
            display: !modifiedOn ? 'N/A' : formatDistanceToNow(modifiedOn),
            sortValue: modifiedOn?.getTime() ?? -1,
          },
          {
            key: 'actions',
            display: (
              <PopperDropdownMenu
                menuItems={[
                  {
                    label: 'Edit',
                    onClick: () => {
                      setSelectedEmployee(employee);
                      setDialogOpen(true);
                    },
                  },
                  {
                    label: 'Specify devices',
                    onClick: () => {
                      setSelectedEmployee(employee);
                      setDevicesDialogOpen(true);
                    },
                  },
                  {
                    label: 'Delete',
                    onClick: () => setDeleteEmployee(employee),
                  },
                ]}
              />
            ),
          },
        ],
      };
    }) ?? [];

  return (
    <Grid container>
      <Grid item xs={12}>
        <Card>
          <CardHeader
            title="Employees"
            action={
              <React.Fragment>
                <input
                  id="icon-button-file"
                  type="file"
                  onChange={onUploadFile}
                  style={{ display: 'none' }}
                />

                <label htmlFor="icon-button-file">
                  <Button
                    color="secondary"
                    component="span"
                    disabled={uploadLoading}
                  >
                    Bulk Upload
                  </Button>
                </label>
                <Button
                  color="primary"
                  onClick={() => {
                    setDialogOpen(true);
                  }}
                >
                  Add Employee
                </Button>
                <IconButton
                  color="secondary"
                  disabled={fetchLoading}
                  onClick={async () => {
                    try {
                      const params = {
                        employeesOnly: '0',
                        visitorsOnly: '0',
                      };
                      if (visitorToggle === 'employees') {
                        params.employeesOnly = '1';
                      } else if (visitorToggle === 'visitors') {
                        params.visitorsOnly = '1';
                      }
                      const queryParams = new URLSearchParams(params);
                      const response = await sendRequest(
                        `api/users?${queryParams}`,
                        {},
                      );
                      setEmployees(await response.json());
                    } catch (ex) {}
                  }}
                >
                  <Refresh />
                </IconButton>
                <PopperDropdownMenu
                  menuItems={[
                    {
                      label: 'Export',
                      onClick: async () => {
                        const params = {
                          format: 'csv',
                          includeHeader: '1',
                          employeesOnly: '0',
                          visitorsOnly: '0',
                        };
                        if (visitorToggle === 'employees') {
                          params.employeesOnly = '1';
                        } else if (visitorToggle === 'visitors') {
                          params.visitorsOnly = '1';
                        }
                        const queryParams = new URLSearchParams(params);
                        const response = await downloadRequest(
                          `api/users?${queryParams}`,
                          {},
                        );
                        if (downloadError) {
                          snackbar.error(downloadError);
                          return;
                        }
                        const csvContent = await response.text();
                        Download(`user_export.csv`, csvContent);
                      },
                    },
                    {
                      label: 'Sync to device',
                      onClick: async () => {
                        if (!selectedEmployees.length) {
                          snackbar.info('Please select some employees');
                          return;
                        }
                        setOpenPushDialog(true);
                      },
                    },
                    {
                      label: 'Sync whitelists in bulk',
                      onClick: async () => {
                        if (
                          !window.confirm(
                            `Syncing whitelists will trigger bulk deletes of employees from your devices. Are you sure you want to proceed?`,
                          )
                        ) {
                          snackbar.info('Sync action cancelled.');
                          return;
                        }
                        syncWhitelists();
                      },
                    },
                    {
                      label: 'Delete',
                      onClick: async () => {
                        if (!selectedEmployees.length) {
                          snackbar.info('Please select some employees');
                          return;
                        }
                        if (
                          !window.confirm(
                            `You are about to deleted ${selectedEmployees.length} employees. Are you sure you want to proceed?`,
                          )
                        ) {
                          snackbar.info('Delete action cancelled.');
                          return;
                        }
                        try {
                          await sendRequest(
                            `api/users/${selectedEmployees.join('-')}/delete`,
                            {
                              postData: {},
                            },
                          );
                        } catch (ex) {
                          snackbar.error(ex);
                          return;
                        }
                        if (error) {
                          snackbar.error(error.message);
                          return;
                        }
                        snackbar.success('Users and biometric data deleted.');
                        setSelectedEmployees([]);

                        try {
                          const params = {
                            employeesOnly: '0',
                            visitorsOnly: '0',
                          };
                          if (visitorToggle === 'employees') {
                            params.employeesOnly = '1';
                          } else if (visitorToggle === 'visitors') {
                            params.visitorsOnly = '1';
                          }
                          const queryParams = new URLSearchParams(params);

                          const response = await sendRequest(
                            `api/users?${queryParams}`,
                            {},
                          );
                          const jsonResponse = await response.json();
                          if (jsonResponse) {
                            setEmployees(jsonResponse);
                          }
                        } catch (ex) {}
                      },
                    },
                  ]}
                />
              </React.Fragment>
            }
          />

          <DelayedLinearProgress loading={fetchLoading} />

          {!fetchLoading && (
            <CardContent>
              <TextField
                value={search}
                onChange={(e) => setSearch(e.currentTarget.value)}
                fullWidth
                placeholder="Type here to search employee by name or employee code"
                helperText="Hint: You can filter in bulk by using a comma seperated list of employee codes. Example: 99999,25,28,2501"
                autoFocus
              />
              <div>
                <label
                  style={{ marginRight: 16 }}
                  onClick={() => setVisitorToggle('employees')}
                >
                  <Radio checked={visitorToggle === 'employees'} />
                  Employees
                </label>
                <label
                  style={{ marginRight: 16 }}
                  onClick={() => setVisitorToggle('visitors')}
                >
                  <Radio checked={visitorToggle === 'visitors'} />
                  Visitors
                </label>
                <label
                  style={{ marginRight: 16 }}
                  onClick={() => setVisitorToggle('both')}
                >
                  <Radio checked={visitorToggle === 'both'} />
                  All users
                </label>
              </div>
            </CardContent>
          )}

          {!rows?.length && !fetchLoading ? (
            visitorToggle === 'visitors' ? (
              <EmptyView>
                <Typography
                  variant="body1"
                  style={{ color: '#000', marginTop: 8 }}
                >
                  The visitor option is part of the Talexio Visitor Management
                  solution.
                  <br />
                  Reach out to our support team for more info on this feature.
                </Typography>
              </EmptyView>
            ) : (
              <EmptyView />
            )
          ) : (
            <div className={classes.tableWrapper}>
              <SortableTable
                columns={columns}
                rows={rows}
                tableProps={{ size: 'small' }}
                defaultSort={{ columnKey: 'name', order: 'asc' }}
                loading={fetchLoading}
                emptyTableText="No Employees found"
              />
            </div>
          )}
          {error && (
            <CardContent>
              <Alert severity="error">
                <Typography variant="body1">{error.message}</Typography>
              </Alert>
            </CardContent>
          )}
        </Card>
        {dialogOpen && (
          <EmployeeDialog
            employee={selectedEmployee}
            open={dialogOpen}
            onClose={async () => {
              setSelectedEmployee(undefined);
              setDialogOpen(false);
              try {
                const params = {
                  employeesOnly: '0',
                  visitorsOnly: '0',
                };
                if (visitorToggle === 'employees') {
                  params.employeesOnly = '1';
                } else if (visitorToggle === 'visitors') {
                  params.visitorsOnly = '1';
                }
                const queryParams = new URLSearchParams(params);
                const response = await sendRequest(
                  `api/users?${queryParams}`,
                  {},
                );
                setEmployees(await response.json());
              } catch (ex) {}
            }}
          />
        )}
        {devicesDialogOpen && selectedEmployee && (
          <EmployeeDevicesDialog
            employee={selectedEmployee}
            open={devicesDialogOpen}
            onClose={async () => {
              setSelectedEmployee(undefined);
              setDevicesDialogOpen(false);
            }}
            setEmployeeAfterSave={(emp) => {
              setEmployees((e) => {
                if (!e) {
                  return;
                }
                const newEmployees = e.slice();
                const existingIndex = newEmployees.findIndex(
                  (lookup) => lookup.id === emp.id,
                );
                if (existingIndex >= 0) {
                  newEmployees[existingIndex] = emp;
                }
                return newEmployees;
              });
            }}
          />
        )}

        <PushDataToDeviceDialog
          dialogProps={{
            open: openPushDialog,
            onClose: () => {
              setOpenPushDialog(false);
            },
          }}
          onSubmit={pushDataSubmit}
        />

        <ConfirmDialog
          dialogProps={{ open: Boolean(deleteEmployee) }}
          title="Delete employee"
          content={
            <React.Fragment>
              {deleteEmployee
                ? `You are about to delete ${deleteEmployee.name} (${deleteEmployee.id}). Are you sure you want to proceed?`
                : 'Select an employee to delete'}
              <Alert
                severity="warning"
                style={{ marginTop: 10, marginBottom: 10 }}
              >
                <Typography variant="body1">
                  This will delete all user info and biometric data for this
                  employee from the device.
                </Typography>
              </Alert>
              <Alert severity="info" style={{ marginBottom: 10 }}>
                <Typography variant="body1">
                  If the employee is not terminated from Talexio and has T&amp;A
                  enabled, it will be created again automatically.
                </Typography>
              </Alert>
              {deleteEmployee && deleteEmployee.role === 'SuperAdministrator' && (
                <Alert severity="error">
                  <Typography variant="body1">
                    You are about to delete a Super Administrator user. Make
                    sure you do not lock yourself out from the devices.
                  </Typography>
                </Alert>
              )}
            </React.Fragment>
          }
          typePrompt="DELETE"
          onCancel={() => setDeleteEmployee(undefined)}
          onConfirm={async () => {
            try {
              if (!deleteEmployee) {
                setDeleteEmployee(undefined);
                return;
              }

              await sendRequest(`api/users/${deleteEmployee.id}/delete`, {
                postData: {},
              });
            } catch (ex) {
              snackbar.error(ex);
              return;
            }
            if (error) {
              snackbar.error(error.message);
              return;
            }
            snackbar.success('User and biometric data deleted.');
            setDeleteEmployee(undefined);

            try {
              const params = {
                employeesOnly: '0',
                visitorsOnly: '0',
              };
              if (visitorToggle === 'employees') {
                params.employeesOnly = '1';
              } else if (visitorToggle === 'visitors') {
                params.visitorsOnly = '1';
              }
              const queryParams = new URLSearchParams(params);
              const response = await sendRequest(
                `api/users?${queryParams}`,
                {},
              );
              const jsonResponse = await response.json();
              if (jsonResponse) {
                setEmployees(jsonResponse);
              }
            } catch (ex) {}
          }}
        />
      </Grid>
    </Grid>
  );

  async function onUploadFile(e: React.ChangeEvent<HTMLInputElement>) {
    const { currentTarget } = e;
    const files = currentTarget.files;

    if (!files || !files.length) {
      throw new Error('Select a file.');
    }

    const file = files[0];

    // Clear input (onChange won't fire if same file is uploaded)
    currentTarget.value = '';

    try {
      const uploadResponse = await uploadRequest('api/user-upload', {
        file,
      });

      if (uploadError) {
        snackbar.error(uploadError);
        return;
      }

      const jsonResponse = await uploadResponse.json();
      snackbar.success(
        `Import complete: ${jsonResponse.newCount} new, ${jsonResponse.updatedCount} updated, ${jsonResponse.unchangedCount} unchanged.`,
      );

      //Refresh
      const response = await sendRequest('api/users', {});
      setEmployees(await response.json());
    } catch (error) {
      snackbar.error(error);
    }
  }

  async function pushDataSubmit(
    deviceSerialNumber: string,
    users: boolean,
    fingerPrint: boolean,
    bioData: boolean,
  ) {
    await sendRequest(`api/devices/${deviceSerialNumber}/push`, {
      postData: {
        employeeIds: selectedEmployees,
        users: users ? '1' : '0',
        fingerPrint: fingerPrint ? '1' : '0',
        bioData: bioData ? '1' : '0',
      },
    });
  }

  async function syncWhitelists() {
    await sendRequest(`api/users/syncWhitelistDeletes`, {
      postData: {},
    });
    snackbar.success(
      `Commands for deleting employees according to whitelists have been scheduled.`,
    );
  }
};
