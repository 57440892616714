import { Button } from '@material-ui/core';
import * as React from 'react';
import { Link, Redirect, Route, Switch } from 'react-router-dom';
import { Login } from 'views';
import { SharedPunchClock } from 'views/SharedPunchClock';
import brandLogo from '../App/assets/talexio-logo.png';
import { useStyles } from './styles';

const talexioUrl = 'https://www.talexio.com';

export const AuthLayout: React.FC = () => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <div className={classes.container}>
        <div className={classes.authContainer}>
          <React.Fragment>
            <div className={classes.logoContainer}>
              <Link to="/">
                <img src={brandLogo} alt="Talexio" className={classes.logo} />
              </Link>
            </div>

            <div>
              <Switch>
                <Route path="/login">
                  <Login />
                </Route>

                <Route path="/punch-clock">
                  <SharedPunchClock />
                </Route>

                <Route>
                  <Redirect to="/login" />
                </Route>
              </Switch>
            </div>

            <div className={classes.footerLinks}>
              <Button
                href={`${talexioUrl}/contact-us/`}
                target="_blank"
                rel="noopener noreferrer"
              >
                Help
              </Button>

              <Button
                href={`${talexioUrl}/privacy-policy-cookie-policy/`}
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy
              </Button>

              <Button
                href={`${talexioUrl}/terms-conditions/`}
                target="_blank"
                rel="noopener noreferrer"
              >
                Terms
              </Button>
            </div>
          </React.Fragment>
        </div>
      </div>
    </React.Fragment>
  );
};
