import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  authContainer: {
    maxWidth: 400,
  },
  logoContainer: {
    textAlign: 'center',
    margin: theme.spacing(6, 0, 6, 0),
  },
  logo: {
    height: 50,
  },
}));
