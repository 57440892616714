import * as React from 'react';
import {
  DialogProps,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  TextField,
  FormControl,
  Button,
  Typography,
  ListItem,
  ListItemText,
  List,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import { useStyles } from './styles';
import { DateTimeFormat } from 'config';
import { format, parse } from 'date-fns';
import { Device } from 'lib/Model/Device';
import { useFetch } from 'hooks';
import { DelayedLinearProgress, SnackbarContext } from 'components';

interface Props extends DialogProps {
  device: Device;
  onUpdated?(device: Device): void;
}

export const DeviceDialog: React.FC<Props> = ({
  device,
  onUpdated,
  ...rest
}) => {
  const classes = useStyles();
  const { loading: fetchLoading, sendRequest, error } = useFetch();
  const [deviceName, setDeviceName] = React.useState(device.name);
  const [notificationEmail, setNotificationEmail] = React.useState(
    device.notificationEmail,
  );
  const [ipAddress, setIpAddress] = React.useState(device.ipAddress);
  const [useServerTime, setUseServerTime] = React.useState(
    device.useServerTime ?? false,
  );
  const snackbar = React.useContext(SnackbarContext);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const response = await sendRequest(`api/devices/${device.serialNumber}`, {
      postData: {
        deviceName,
        notificationEmail,
        ipAddress,
        useServerTime,
      },
    });
    if (error) {
      snackbar.error(error.message);
      return;
    }
    snackbar.success('Device updated.');
    onUpdated && onUpdated(await response.json());
    rest && rest.onClose && rest.onClose(e, 'escapeKeyDown');
  };

  return (
    <Dialog maxWidth="md" fullWidth {...rest}>
      <DelayedLinearProgress loading={fetchLoading} />
      <DialogTitle>{device.name}</DialogTitle>
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <Grid container>
            <Grid item md={8}>
              <FormControl className={classes.formControl} fullWidth>
                <TextField
                  label="Serial number"
                  type="text"
                  value={device.serialNumber}
                  disabled
                  required
                />
              </FormControl>

              <FormControl className={classes.formControl} fullWidth>
                <TextField
                  label="Name"
                  value={deviceName}
                  onChange={(e) => setDeviceName(e.target.value)}
                  required
                  autoFocus
                />
              </FormControl>

              <FormControl className={classes.formControl} fullWidth>
                <TextField
                  type="email"
                  label="Notification email"
                  value={notificationEmail}
                  onChange={(e) => {
                    setNotificationEmail(e.target.value);
                  }}
                  helperText="By specifying an email address, you will receive a notification email when we detect that this device is offline."
                />
              </FormControl>

              <FormControl className={classes.formControl} fullWidth>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="isWebDevice"
                      checked={device.isWebDevice ?? false}
                      readOnly
                    />
                  }
                  label="Web punch clock (using CDM web punch clock)"
                />
              </FormControl>

              {!device.isWebDevice && (
                <FormControl className={classes.formControl} fullWidth>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="useServerTime"
                        checked={useServerTime ?? false}
                        onChange={(e) =>
                          setUseServerTime(e.target.checked ?? false)
                        }
                      />
                    }
                    label="Use server time instead of DST settings on device"
                  />
                </FormControl>
              )}

              <FormControl className={classes.formControl} fullWidth>
                <TextField
                  label="IP Address"
                  type="text"
                  value={ipAddress}
                  onChange={(e) => setIpAddress(e.target.value)}
                  disabled={!device.isWebDevice}
                />
              </FormControl>

              <FormControl className={classes.formControl} fullWidth>
                <TextField
                  disabled
                  label="Created On"
                  value={format(
                    parse(device.createdOn, DateTimeFormat.GQL, new Date()),
                    DateTimeFormat.LONG,
                  )}
                />
              </FormControl>

              {device.modifiedOn && (
                <FormControl className={classes.formControl} fullWidth>
                  <TextField
                    disabled
                    fullWidth
                    label="Modified On"
                    value={format(
                      parse(device.modifiedOn, DateTimeFormat.GQL, new Date()),
                      DateTimeFormat.LONG,
                    )}
                  />
                </FormControl>
              )}
            </Grid>
            <Grid item md={4}>
              <Typography variant="subtitle2">Info</Typography>
              Last ping:{' '}
              {format(
                parse(device.ping, DateTimeFormat.GQL, new Date()),
                DateTimeFormat.LONG,
              )}
              <p />
              Multibio: {device.multiBioDataSupport}
              <br />
              FW: {device.firmwareVersion}
              <br />
              Pushver: {device.pushver}
              <br />
              Face version: {device.faceVersion}
              <br />
              FP version: {device.fpVersion}
              <br />
              PV version: {device.pvVersion}
              <p />
              Flash size: {device.flashSize}
              <br />
              Free flash size: {device.freeFlashSize}
              <br />
              {device.flashSize > 0 && (
                <span>
                  Flash usage:{' '}
                  {(
                    ((device.flashSize - device.freeFlashSize) /
                      device.flashSize) *
                    100
                  ).toFixed(2)}
                  %
                </span>
              )}
              <p />
              <Typography variant="subtitle2">Pending Commands</Typography>
              {!device.commands.length ? (
                <span>No pending commands</span>
              ) : (
                <List>
                  {device.commands.map((command) => (
                    <ListItem>
                      <ListItemText primary={command.commandText} />
                    </ListItem>
                  ))}
                </List>
              )}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={(e) =>
              rest && rest.onClose && rest.onClose(e, 'escapeKeyDown')
            }
          >
            Close
          </Button>
          <Button type="submit" color="primary" disabled={fetchLoading}>
            Save Changes
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
