import {
  Button,
  Card,
  CardContent,
  CardHeader,
  makeStyles,
  TextField,
  Typography,
} from '@material-ui/core';
import { Alert, Skeleton } from '@material-ui/lab';
import classNames from 'classnames';
import { SnackbarContext } from 'components';
import { useAudio } from 'hooks/useAudio';
import * as React from 'react';
import { useParams } from 'react-router-dom';
import { useFetch } from '../../hooks';

const useStyles = makeStyles((theme) => ({
  card: {
    padding: theme.spacing(0, 0.5, 2, 0.5),
    minWidth: 400,
  },
  cardHeader: {
    background: 'none',
    textAlign: 'center',
    paddingTop: theme.spacing(3),
    paddingBottom: 0,
  },
  cardHeaderTitle: {
    color: theme.palette.primary.main,
  },
  punchButton: {
    display: 'block',
    padding: theme.spacing(3),
    fontSize: 25,
    marginBottom: 10,
    width: '100%',
  },
  punchIn: {
    backgroundColor: '#008080',
    color: '#ffffff',
    '&:hover': {
      color: '#444444',
    },
  },
  punchOut: {
    backgroundColor: '#CE5757',
    color: '#FFFFFF',
    '&:hover': {
      color: '#444444',
    },
  },
  cancel: {
    fontSize: 18,
    padding: theme.spacing(2),
  },
  pinInput: {
    textAlign: 'center',
    fontSize: 50,
    padding: theme.spacing(1, 0),
  },
  pinContainer: {
    padding: theme.spacing(4, 0),
    marginBottom: theme.spacing(2),
  },
}));

interface PunchState {
  punchAction?: 'in' | 'out';
  userPin?: string;
  passcode?: string;
}

export const SharedPunchClock: React.FC = () => {
  const classes = useStyles();

  const { loading: saveLoading, sendRequest, error } = useFetch();

  const [errorText, setErrorText] = React.useState<string | undefined>();
  React.useEffect(() => {
    setErrorText(error?.message);
  }, [error, setErrorText]);

  const [form, setForm] = React.useState<PunchState>({});
  const snackbar = React.useContext(SnackbarContext);
  const { authKey } = useParams<{ authKey: string }>();

  const { toggle: punchInAudio } = useAudio('/audio/punchin.mp3');
  const { toggle: punchOutAudio } = useAudio('/audio/punchout.mp3');
  const { toggle: errorAudio } = useAudio('/audio/error.mp3');
  const { toggle: thankyouAudio } = useAudio('/audio/thankyou.mp3');
  const { toggle: cancelledAudio } = useAudio('/audio/cancelled.mp3');

  return (
    <Card className={classes.card}>
      <CardHeader
        className={classes.cardHeader}
        title={form.punchAction ? 'User Identification' : 'Talexio Punch Clock'}
        subheader={
          form.punchAction
            ? 'Please enter your PIN and Passcode.'
            : 'Select your punch action below.'
        }
        classes={{ title: classes.cardHeaderTitle }}
      />

      <CardContent>{getForm()}</CardContent>
    </Card>
  );

  function getForm() {
    if (saveLoading) {
      return <Skeleton variant="rect" style={{ height: 400 }} />;
    }

    if (!form.punchAction) {
      return (
        <div>
          <Button
            color="default"
            variant="contained"
            className={classNames(classes.punchButton, classes.punchIn)}
            onClick={() => {
              setForm({
                punchAction: 'in',
                userPin: undefined,
                passcode: undefined,
              });
              punchInAudio();
            }}
          >
            Punch In
          </Button>
          <Button
            color="default"
            variant="contained"
            className={classNames(classes.punchButton, classes.punchOut)}
            onClick={() => {
              setForm({
                punchAction: 'out',
                userPin: undefined,
                passcode: undefined,
              });
              punchOutAudio();
            }}
          >
            Punch Out
          </Button>
        </div>
      );
    }

    if (!saveLoading) {
      return (
        <form
          onSubmit={async (e) => {
            e.stopPropagation();
            e.preventDefault();
            await submitPunch();
            return false;
          }}
        >
          <div className={classes.pinContainer}>
            <TextField
              variant="outlined"
              label="Your PIN"
              type="password"
              inputMode="numeric"
              value={form.userPin}
              onChange={(e) => {
                if (!e.target) {
                  return;
                }
                const userPin = e.target.value;
                setForm((f) => ({
                  ...f,
                  userPin,
                }));
              }}
              autoComplete="none"
              InputLabelProps={{ shrink: true }}
              inputProps={{
                className: classes.pinInput,
              }}
              style={{ marginBottom: 16 }}
              fullWidth
              autoFocus
              required
            />
            <TextField
              variant="outlined"
              label="Your Passcode"
              type="password"
              inputMode="numeric"
              value={form.passcode}
              onChange={(e) => {
                if (!e.target) {
                  return;
                }
                const passcode = e.target.value;
                setForm((f) => ({
                  ...f,
                  passcode,
                }));
              }}
              autoComplete="none"
              InputLabelProps={{ shrink: true }}
              inputProps={{
                className: classes.pinInput,
              }}
              fullWidth
              required
            />
          </div>
          {errorText && (
            <Alert severity="error" style={{ marginBottom: 8 }}>
              <Typography variant="body1">{errorText}</Typography>
            </Alert>
          )}
          <Button
            variant="contained"
            color="default"
            type="submit"
            className={classNames(
              classes.punchButton,
              form.punchAction === 'in' ? classes.punchIn : classes.punchOut,
            )}
          >
            Punch {form.punchAction}
          </Button>
          <Button
            variant="contained"
            color="default"
            className={classNames(classes.punchButton, classes.cancel)}
            onClick={() => {
              setForm({});
              setErrorText(undefined);
              cancelledAudio();
            }}
          >
            Cancel
          </Button>
        </form>
      );
    }

    // Have pin and have punch action, means we are done
    return (
      <div>
        <Typography variant="h6">Thank you!</Typography>
        <Typography variant="body1">Screen will reset in 5 seconds</Typography>
      </div>
    );
  }

  async function submitPunch() {
    try {
      const { userPin, punchAction, passcode } = form;

      if (!userPin || !punchAction || !passcode) {
        return;
      }

      setErrorText(undefined);
      await sendRequest('api/shared-punchclock', {
        postData: {
          userPin,
          punchAction,
          authKey,
          passcode,
        },
      });

      thankyouAudio();
      snackbar.success('Punch submitted successfully');

      setForm({});
    } catch (error) {
      errorAudio();
      snackbar.error(error);
    }
  }
};
