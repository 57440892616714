import { setGlobalState } from '../App.state';
import Cookies from 'js-cookie';

export function logout() {
  setGlobalState('clockingsAuthUser', null);

  Cookies.remove('clockingsAuthUser', {
    path: '/',
  });
}
