import * as React from 'react';
import {
  Grid,
  Card,
  Button,
  CardHeader,
  IconButton,
  CardContent,
  Typography,
} from '@material-ui/core';
import { AdminUser } from 'lib/Model/AdminUser';
import { DelayedLinearProgress, EmptyView } from 'components';
import { formatDistanceToNow, parse } from 'date-fns';
import { useStyles } from './styles';
import { Refresh } from '@material-ui/icons';
import { AdminUserDialog } from './components';
import { useFetch } from 'hooks';
import { SortableTable } from 'components/SortableTable';
import { SortableTableHeader } from 'components/SortableTable/components';
import { Alert } from '@material-ui/lab';
import { DateTimeFormat } from 'config';

export const AdminUsers: React.FC = () => {
  const classes = useStyles();

  const [adminUsers, setAdminUsers] = React.useState<AdminUser[] | undefined>();
  const [selectedAdminUser, setSelectedAdminUser] = React.useState<
    AdminUser | undefined
  >();
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const { loading: fetchLoading, sendRequest, error } = useFetch();

  React.useEffect(() => {
    async function fetchData() {
      try {
        const response = await sendRequest('api/admin-users', {});
        const jsonResponse = await response.json();
        if (jsonResponse) {
          setAdminUsers(jsonResponse);
        }
      } catch (ex) {}
    }
    fetchData();
  }, [setAdminUsers, sendRequest]);

  const columns: SortableTableHeader[] = [
    { key: 'username', label: 'Username', sortable: true },
    { key: 'created', label: 'Created', sortable: true },
    { key: 'modified', label: 'Modified', sortable: true },
  ];

  const rows =
    adminUsers?.map((adminUser) => {
      const createdOn = parse(
        adminUser.createdOn,
        DateTimeFormat.GQL,
        new Date(),
      );
      const modifiedOn = adminUser.modifiedOn
        ? parse(adminUser.modifiedOn, DateTimeFormat.GQL, new Date())
        : undefined;
      return {
        key: adminUser.id.toString(),
        cells: [
          {
            key: 'username',
            display: (
              <Button
                variant="text"
                color="primary"
                className={classes.primaryLink}
                onClick={() => {
                  setSelectedAdminUser(adminUser);
                  setDialogOpen(true);
                }}
              >
                {adminUser.username}
              </Button>
            ),
            sortValue: adminUser.username,
          },
          {
            key: 'created',
            display: formatDistanceToNow(createdOn),
            sortValue: createdOn.getTime() ?? -1,
          },
          {
            key: 'modified',
            display: !modifiedOn ? 'N/A' : formatDistanceToNow(modifiedOn),
            sortValue: modifiedOn?.getTime() ?? -1,
          },
        ],
      };
    }) ?? [];

  return (
    <Grid container>
      <Grid item xs={12}>
        <Card>
          <CardHeader
            title="Admin Users"
            action={
              <React.Fragment>
                <Button
                  color="primary"
                  onClick={() => {
                    setDialogOpen(true);
                  }}
                >
                  Add Admin User
                </Button>
                <IconButton
                  color="secondary"
                  disabled={fetchLoading}
                  onClick={async () => {
                    try {
                      const response = await sendRequest('api/admin-users', {});
                      setAdminUsers(await response.json());
                    } catch (ex) {}
                  }}
                >
                  <Refresh />
                </IconButton>
              </React.Fragment>
            }
          />

          <DelayedLinearProgress loading={fetchLoading} />

          {!rows?.length ? (
            <EmptyView />
          ) : (
            <SortableTable
              columns={columns}
              rows={rows}
              tableProps={{ size: 'small' }}
              defaultSort={{ columnKey: 'name', order: 'asc' }}
              loading={fetchLoading}
              emptyTableText="No admin users found"
            />
          )}
          {error && (
            <CardContent>
              <Alert severity="error">
                <Typography variant="body1">{error.message}</Typography>
              </Alert>
            </CardContent>
          )}
        </Card>
        {dialogOpen && (
          <AdminUserDialog
            adminUser={selectedAdminUser}
            open={dialogOpen}
            onClose={async () => {
              setSelectedAdminUser(undefined);
              setDialogOpen(false);
              try {
                const response = await sendRequest('api/admin-users', {});
                setAdminUsers(await response.json());
              } catch (ex) {}
            }}
          />
        )}
      </Grid>
    </Grid>
  );
};
