import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  TextField,
} from '@material-ui/core';
import { StandardTextFieldProps } from '@material-ui/core/TextField';
import { pascalToWords } from 'lib';
import * as React from 'react';

export const FormField = React.forwardRef<
  HTMLDivElement,
  StandardTextFieldProps & { selectOnFocus?: boolean }
>(({ selectOnFocus, ...props }, ref) => {
  const { type, name, value, label, onChange, defaultValue, ...rest } = props;

  if (type === 'checkbox') {
    return (
      // @ts-ignore type issues with onBlur & onFocus
      <FormControl fullWidth margin="normal" ref={ref} {...rest}>
        <FormGroup>
          <FormControlLabel
            label={label || (name && pascalToWords(name)) || ''}
            control={
              <Checkbox
                name={name}
                defaultValue={String(defaultValue)}
                checked={value ? Boolean(value) : false}
                onChange={onChange}
              />
            }
          />
        </FormGroup>
      </FormControl>
    );
  }

  if (
    typeof value !== 'undefined' &&
    value !== null &&
    typeof value !== 'number' &&
    typeof value !== 'string'
  ) {
    throw new Error(`Invalid value "${value}" for "${name}" field`);
  }

  return (
    <TextField
      ref={ref}
      margin="normal"
      fullWidth
      label={label || (name && pascalToWords(name)) || ''}
      onFocus={(e) => {
        if (selectOnFocus) {
          e.target.select();
        }
      }}
      {...props}
    />
  );
});
