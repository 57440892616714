import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  primaryLink: {
    color: theme.palette.primary.main,
    textTransform: 'none',
    textDecoration: 'none',
    fontWeight: theme.typography.fontWeightMedium,
    padding: theme.spacing(0.7, 2),
  },
  secondaryLink: {
    color: theme.palette.secondary.main,
    fontWeight: theme.typography.fontWeightMedium,
    textDecoration: 'none',
  },
  defaultLink: {
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightMedium,
    textDecoration: 'none',
  },
  badgeSpacer: {
    marginRight: theme.spacing(1),
  },
  badge: {
    right: 3,
    top: 5,
  },
  nliBadge: {
    top: 'auto',
    bottom: -12,
    right: 3,
    padding: 0,
    backgroundColor: '#fff',
    boxShadow: '0px 0px 5px #555',
  },
  nliBadgeIcon: {
    color: theme.palette.error.main,
  },
  profileHeading: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  profileHeadingAvatar: {
    marginRight: theme.spacing(1),
    border: '1px solid #fff',
  },
}));
