import { makeStyles, Typography } from '@material-ui/core';
import SvgIcon from '@material-ui/core/SvgIcon';
import classNames from 'classnames';
import * as React from 'react';

const useStyles = makeStyles({
  wrapper: {
    width: '100%',
    display: 'inline-flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 30,
  },
  image: {
    height: 60,
  },
  text: {
    textAlign: 'center',
    color: '#bbb',
    marginTop: 4,
  },
});

export const EmptyView: React.FC<React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>> = ({ children = 'Nothing here yet.', className, ...rest }) => {
  const { wrapper, text } = useStyles();

  return (
    <div className={classNames(wrapper, className)} {...rest}>
      <SvgIcon
        style={{ height: 24, width: 24 }}
        viewBox="0 0 100 100"
        preserveAspectRatio="xMidYMid meet"
      >
        <circle
          id="emptyview_circle1"
          cx="65"
          cy="76"
          r="10.5"
          opacity="1"
          fill="rgba(55,71,79,0)"
          fill-opacity="1"
          stroke="rgba(55,71,79,0.32)"
          stroke-width="8"
          stroke-opacity="1"
          stroke-dasharray=""
        ></circle>
        <circle
          id="emptyview_circle2"
          cx="27"
          cy="58"
          r="13.5"
          opacity="1"
          fill="rgba(55,71,79,0)"
          fill-opacity="1"
          stroke="rgba(55,71,79,0.32)"
          stroke-width="8"
          stroke-opacity="1"
          stroke-dasharray=""
        ></circle>
        <circle
          id="emptyview_circle3"
          cx="69"
          cy="35"
          r="15.5"
          opacity="1"
          fill="rgba(55,71,79,0)"
          fill-opacity="1"
          stroke="rgba(55,71,79,0.32)"
          stroke-width="8"
          stroke-opacity="1"
          stroke-dasharray=""
        ></circle>
      </SvgIcon>
      <Typography
        className={text}
        component={typeof children === 'string' ? 'p' : 'div'}
      >
        {children}
      </Typography>
    </div>
  );
};
