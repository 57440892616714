import { useGlobalState } from 'App.state';
import { AppLayout, AuthLayout } from 'layouts';
import { GuestLayout } from 'layouts';
import * as React from 'react';
import { useLocation } from 'react-router-dom';

export const LayoutSwitcher: React.FC = () => {
  const [clockingsAuthUser] = useGlobalState('clockingsAuthUser');
  const location = useLocation();
  const isGuestLayout =
    location.pathname.indexOf('/punch-clock') !== -1 ||
    location.pathname.indexOf('/visitors') !== -1;

  if (isGuestLayout) {
    return <GuestLayout />;
  }
  return clockingsAuthUser !== null ? <AppLayout /> : <AuthLayout />;
};
