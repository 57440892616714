import * as React from 'react';
import {
  Popper,
  ClickAwayListener,
  Grow,
  Paper,
  makeStyles,
  IconButton,
  MenuItem,
  MenuList,
  Button,
} from '@material-ui/core';
import classNames from 'classnames';
import MenuIcon from '@material-ui/icons/MoreVert';

interface PopperMenuItem {
  label: string;
  onClick(): void;
}

interface Props {
  menuItems: Array<null | PopperMenuItem>;
  label?: string;
}

const useStyles = makeStyles((theme) => ({
  popperClose: {
    pointerEvents: 'none',
  },
  popperResponsive: {
    zIndex: 9999,
    [theme.breakpoints.down('md')]: {
      ...theme.mixins.popperResponsive,
    },
  },
  dropdown: {
    ...theme.mixins.dropdown,
    padding: 0,
  },
  dropdownMenu: {
    padding: 0,
  },
  dropdownItem: {
    ...theme.mixins.dropdownItem,
    margin: 0,
  },
  label: {
    marginRight: theme.spacing(0.5),
  },
}));

export const PopperDropdownMenu: React.FC<Props> = ({ menuItems, label }) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | undefined>();
  const open = Boolean(anchorEl);
  const classes = useStyles();

  return (
    <React.Fragment>
      {label ? (
        <Button onClick={(e) => setAnchorEl(e.currentTarget)}>
          <span className={classes.label}>{label}</span>
          <MenuIcon fontSize="small" />
        </Button>
      ) : (
        <IconButton onClick={(e) => setAnchorEl(e.currentTarget)}>
          <MenuIcon fontSize="small" />
        </IconButton>
      )}
      <Popper
        placement="bottom-end"
        open={open}
        className={classNames(classes.popperResponsive, {
          [classes.popperClose]: !open,
        })}
        anchorEl={anchorEl}
        transition
      >
        {({ TransitionProps }) => (
          <ClickAwayListener onClickAway={() => setAnchorEl(undefined)}>
            <Grow {...TransitionProps}>
              <Paper className={classes.dropdown}>
                <MenuList role="menu" className={classes.dropdownMenu}>
                  {menuItems?.map((m) =>
                    m !== null ? (
                      <MenuItem
                        key={m.label}
                        component="label"
                        onClick={() => {
                          m.onClick();
                          setAnchorEl(undefined);
                        }}
                        className={classes.dropdownItem}
                      >
                        {m.label}
                      </MenuItem>
                    ) : null,
                  )}
                </MenuList>
              </Paper>
            </Grow>
          </ClickAwayListener>
        )}
      </Popper>
    </React.Fragment>
  );
};
