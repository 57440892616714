import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(2, 0),
  },
  userPic: {
    maxWidth: '100%',
    maxHeight: 300,
    borderRadius: theme.spacing(1),
    padding: theme.spacing(1),
    borderSize: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.grey[300],
  },
  noPic: {
    height: 300,
    borderRadius: theme.spacing(1),
    background: theme.palette.grey[300],
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: theme.spacing(2),
  },
  chip: {
    margin: theme.spacing(0.8, 0.5, 0, 0),
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
}));
