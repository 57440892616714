import { CardContent, Icon, Slide, Typography } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import {
  CloudUpload,
  People,
  Schedule,
  SignalCellular4Bar,
  SignalCellularOff,
} from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import { DelayedLinearProgress } from 'components';
import { formatDistanceToNow, parseISO } from 'date-fns';
import { useFetch } from 'hooks';
import * as React from 'react';
import { useStyles } from './styles';

interface DashboardStats {
  transactionsTodayCount: number;
  uniqueUsersPunchedTodayCount: number;
  lastFtpSyncDate: string;
  unHealthyDeviceCount: number;
  healthDeviceCount: number;
}

export const DashboardStats: React.FC = () => {
  const classes = useStyles();
  const [dashboardStats, setDashboardStats] = React.useState<
    DashboardStats | undefined
  >();
  const { loading: fetchLoading, sendRequest, error } = useFetch();

  React.useEffect(() => {
    async function fetchData() {
      try {
        const response = await sendRequest('api/dashboard-stats', {});
        const jsonResponse = await response.json();
        if (jsonResponse) {
          setDashboardStats(jsonResponse);
        }
      } catch (ex) {}
    }
    fetchData();
  }, [setDashboardStats, sendRequest]);

  return (
    <React.Fragment>
      <Grid container>
        <DelayedLinearProgress loading={fetchLoading} />
        {error && (
          <Grid item xs={12}>
            <Card className={classes.card}>
              <CardContent>
                <Alert severity="error">
                  <Typography variant="body1">{error.message}</Typography>
                </Alert>
              </CardContent>
            </Card>
          </Grid>
        )}
      </Grid>

      {!fetchLoading && (
        <Slide in={!fetchLoading} direction="left" timeout={200}>
          <Grid
            container
            className={classes.overview}
            spacing={2}
            style={{ paddingTop: '0.55em' }}
          >
            <Grid item className={classes.statCard}>
              <div className={classes.info}>
                <Typography variant="caption">Today's</Typography>
                <Typography variant="h5">Punches</Typography>
                <Icon className={classes.icon}>
                  <Schedule />
                </Icon>
              </div>
              <div className={classes.total}>
                <Typography variant="h2">
                  {dashboardStats?.transactionsTodayCount}
                </Typography>
              </div>
            </Grid>
            <Grid item className={classes.statCard}>
              <div className={classes.info}>
                <Typography variant="caption">Today's</Typography>
                <Typography variant="h5">Attendees</Typography>
                <Icon className={classes.icon}>
                  <People />
                </Icon>
              </div>
              <div className={classes.total}>
                <Typography variant="h2">
                  {dashboardStats?.uniqueUsersPunchedTodayCount}
                </Typography>
              </div>
            </Grid>
            <Grid item className={classes.statCard}>
              <div className={classes.info}>
                <Typography variant="caption">Current</Typography>
                <Typography variant="h5">Healthy Devices</Typography>
                <Icon className={classes.icon}>
                  <SignalCellular4Bar />
                </Icon>
              </div>
              <div className={classes.total}>
                <Typography variant="h2">
                  {dashboardStats?.healthDeviceCount}
                </Typography>
              </div>
            </Grid>
            <Grid item className={classes.statCard}>
              <div className={classes.info}>
                <Typography variant="caption">Current</Typography>
                <Typography variant="h5">Unhealthy Devices</Typography>
                <Icon className={classes.icon}>
                  <SignalCellularOff />
                </Icon>
              </div>
              <div className={classes.total}>
                <Typography variant="h2">
                  {dashboardStats?.unHealthyDeviceCount}
                </Typography>
              </div>
            </Grid>
            {dashboardStats?.lastFtpSyncDate && (
              <Grid item className={classes.statCard}>
                <div className={classes.info} style={{ width: '50%' }}>
                  <Typography variant="caption">Latest</Typography>
                  <Typography variant="h5">FTP Sync</Typography>
                  <Icon className={classes.icon}>
                    <CloudUpload />
                  </Icon>
                </div>
                <div className={classes.total} style={{ textAlign: 'right' }}>
                  <Typography variant="h2" style={{ fontSize: '1.8em' }}>
                    {formatDistanceToNow(
                      parseISO(dashboardStats.lastFtpSyncDate),
                    )}
                  </Typography>
                </div>
              </Grid>
            )}
          </Grid>
        </Slide>
      )}
    </React.Fragment>
  );
};
